import {combineReducers} from "redux";

import appReducer from './modules/appReducer'
import customerReducer from './modules/Customer/customerReducer'
import mosqueReducer from './modules/Mosque/mosqueReducer'

export default combineReducers({
    appReducer,
    customerReducer,
    mosqueReducer
});
