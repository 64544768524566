import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOnboardingMemberDetails} from "../../../customerAction";
import Input from "../../../../../components/Input";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";

function MosqueStepOne({websiteData}) {
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});

    const memberDetails = useSelector(
        (state) => state.customerReducer.memberDetails
    );

    const handleChangeUser = (keyName, keyValue) => {
        dispatch(setOnboardingMemberDetails(keyName, keyValue));
    };

    const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!memberDetails.contact_email || !isEmail(memberDetails.contact_email)) {
            isValid = false;
            error["contact_email"] = "Please enter valid email address";
        }
        return {
            isValid,
            error,
        };
    };

    const handleEmailValidation = () => {
        setErrors({});
        let validate = handleValidation();
        if (!validate.isValid) {
            setErrors(validate.error);
        }
    };

    return (
        <div>
            <div className="mosque-form">
                <h4>{websiteData?.REGISTER_FORM_FIRST_SEGMENT_TITLE}</h4>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <label>
                            {websiteData?.REGISTER_FORM_FIRST_SEGMENT_FULL_NAME_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            value={memberDetails?.contact_name}
                            onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                            type="text"
                            placeholder="Enter Full Name"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label>
                            {websiteData?.REGISTER_FORM_FIRST_SEGMENT_EMAIL_ADDRESS_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            onBlur={() => handleEmailValidation()}
                            value={memberDetails?.contact_email}
                            onChange={(e) =>
                                handleChangeUser("contact_email", e.target.value)
                            }
                            type="text"
                            placeholder="Enter Email Address"
                            className="input-box"
                        />
                        {errors.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label>
                            {websiteData?.REGISTER_FORM_FIRST_SEGMENT_CONTACT_NAME_TEXT}
                        </label>
                        <Input
                            value={memberDetails.contact_phone}
                            countryCodes={true}
                            setCountryCode={(e) => handleChangeUser("country_code", e)}
                            onChange={(e) =>
                                handleChangeUser("contact_phone", e.target.value)
                            }
                            placeholder="785-785-4644"
                            className="input-box"
                            type="number"
                            pattern="[0-9]"
                            height="52px"
                            overflow="unset"
                        />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label>
                            {websiteData?.REGISTER_FORM_FIRST_SEGMENT_CONTACT_ADDRESS_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            value={memberDetails?.address}
                            onChange={(e) => handleChangeUser("address", e.target.value)}
                            type="text"
                            placeholder="Enter Address"
                            className="input-box"
                        />
                    </div>
                </div>
                <br/>
            </div>
        </div>
    );
}

export default MosqueStepOne;
