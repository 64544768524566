import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { AUTHENTICATED } from "../../../../constants/authEnums";
import LoginPage from "../../../Customer/pages/LoginPage/LoginPage";

const RedirectPage = ({ isMosque, isCustomer }) => {
  const navigate = useNavigate();

  const authState = useSelector((state) => state?.appReducer?.authState);

  useEffect(() => {
    if (authState === AUTHENTICATED) {
      if (isMosque) {
        navigate(`/login?isMosque`);
      } else if (isCustomer) {
        navigate(`/login`);
      }
    }
    navigate(`/login`);
    return;
  }, [authState])

  return <LoginPage />;
};

export default RedirectPage;
