import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import Congratulation from "./Congratulation";
import SelectMemberBoxPlan from "./SelectMemberBoxPlan";
import {fetchActiveMosquePlans, setOnboardingMemberDetails} from "../../../customerAction";

const MosqueStepThree = ({ websiteData }) => {
  const dispatch = useDispatch();

  const memberDetails = useSelector((state) => state.customerReducer.memberDetails);
  const is_whatsapp_opted = useSelector((state) => state.customerReducer.memberDetails?.is_whatsapp_opted);

  useEffect(() => {
      if (memberDetails && memberDetails.entity_id) {
        dispatch(fetchActiveMosquePlans(memberDetails.entity_id))
      }
  }, [memberDetails])

  const { isAppliedTnC } = memberDetails;

  const is_onboarded_member = useSelector((state) => state.customerReducer.is_onboarded_member);

  const handleChange = (keyName, keyValue) => {
    dispatch(setOnboardingMemberDetails(keyName, keyValue));
  };

  return (
    <div style={{ margin: "30px 0px" }}>
      {!is_onboarded_member ? (
        <>
          <div>
            <label style={{ marginBottom: "6px" }}>
              {websiteData?.REGISTER_FORM_THIRD_SEGMENT_SELECT_PAYMENT_TEXT}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <br />
            <SelectMemberBoxPlan />
          </div>
          {
            memberDetails?.contact_phone &&
              <div className="checkbox-container mt-4">
                {websiteData?.REGISTER_FORM_THIRD_SEGMENT_FIRST_AGREE_TEXT}
                {is_whatsapp_opted && <input type="checkbox" checked={is_whatsapp_opted}/>}
                <span className="checkmark" onClick={() => handleChange("is_whatsapp_opted", !is_whatsapp_opted)}/>
              </div>
          }
          <div className="checkbox-container mt-4">
            {websiteData?.REGISTER_FORM_THIRD_SEGMENT_SECOND_AGREE_TEXT}
            {isAppliedTnC && <input type="checkbox" checked={isAppliedTnC} />}
            <span className="checkmark" onClick={() => handleChange("isAppliedTnC", !isAppliedTnC)} />
          </div>
        </>
      ) : (
        <Congratulation />
      )}
    </div>
  );
}

export default MosqueStepThree;
