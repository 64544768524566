import React, {useEffect} from "react";
import Header from "../../../../components/Header/Header";
import Banner from "./components/Banner";
import AboutUs from "./components/AboutUs";
import Footer from "../../../../components/Footer/Footer";
import Onboarded from "../Onboarded/Onboarded";
import queryString from "../../../../util/queryString";
import Box from "../../../../components/Box";
import styled from "styled-components";
import {APP_TOKEN, USER_ID, USER_TYPE, USER_TYPE_CUSTOMER,} from "../../../../constants/appEnums";
import {fetchCustomerProfile} from "../../customerAction";
import {useDispatch} from "react-redux";
import Apart from "./components/Apart";
import OurFeatures from "./components/OurFeatures";
import ContactForm from "./components/ContactForm";

const Container = styled(Box)`
  height: 100vh;
  // overflow: scroll;
  padding-top: 50px;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

function HomePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        let token = window.localStorage.getItem(APP_TOKEN);
        let user_type = window.localStorage.getItem(USER_TYPE);
        let user_id = window.localStorage.getItem(USER_ID);
        if (token) {
            if (user_type === USER_TYPE_CUSTOMER) {
                dispatch(fetchCustomerProfile(user_id));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        const queryObj = queryString.parse(window.location.search);
        if (queryObj?.register === "true") {
            const categoryElement = document.getElementById(`onboarding-state`);
            categoryElement.scrollIntoView({behavior: "smooth"});
        }
    });

    return (
        <Box>
            <Header/>
            <Container>
                <Banner/>
                {/* <FamilySlider /> */}
                <Onboarded/>
                {/*<Apart/>*/}
                {/* <AboutUs /> */}
                {/*<OurFeatures/>*/}
                {/*<ContactForm/>*/}
                {/* <ContactUs /> */}
                <AboutUs/>
                <Footer/>
            </Container>
        </Box>
    );
}

export default HomePage;
