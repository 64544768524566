import styled from "styled-components";
import {
  border,
  space,
  layout,
  variant,
  typography,
  color, shadow,
} from "styled-system";

export const DropDownContainer = styled("div")`
  position: relative;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  position: relative;
  height: ${(props) => (props.height ? props.height : "35px")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "auto")};
  ${space}
  ${layout}
`;
const outlinedVariantCss = {
  "border-radius": "4px",
  "line-height": "14px",
  color: "#222222",
  "text-align": "center",
  //"&:hover": {
  //  color: "#D0004B !important",
  //  borderColor: "#D0004B !important",
  //  "& svg": {
  //    fill: "#D0004B !important",
  //  },
  //},
  padding: "3px 40px 3px 9px",
  "max-width": "none",
};
export const DropDownHeader = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${(props) => props.bg ?? props.theme.colors.light};
  border-radius: 0.25rem;
  border: none;
  padding: 8px 32px 9px 9px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};

  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  word-break: break-word;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "120px")};
  width: ${(props) => (props.width ? props.width : "146px")};
  height: ${(props) => (props.height ? props.height : "35px")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "auto")};
  margin: 0 !important;
  box-shadow: 0px 2px 6px #13124212;
  border-color: ${(props) =>
    props.variant === "disabled" ? "#989898" : "black"};
  color: ${(props) => {
    if (props.variant === "disabled") {
      return "#989898";
    }
    if (props.variant === "outlinedGreen" && props.isOpen) {
      return "#1D7746";
    }
    return props.color ?? "#000000";
  }};

  ${border}
  ${shadow}
  ${space}
  ${layout}
  ${typography}
  ${variant({
    variants: {
      light: {
        backgroundColor: "white",
        border: "1px solid currentColor",
      },
      dark: {
        backgroundColor: "#E5E5E5",
      },
      outlined: {
        ...outlinedVariantCss,
        backgroundColor: "#ffffff",
      },
      outlinedGreen: {
        ...outlinedVariantCss,
        backgroundColor: "#ffffff",
      },
      outlinedGrey: {
        ...outlinedVariantCss,
        borderColor: "#C4C4C4",
        backgroundColor: "#ffffff",
      },
      lighterGrey: {
        backgroundColor: "#EFF1F3",
        border: "none",
        borderRadius: "8px !important",
      },
      white: {
        backgroundColor: "#ffffff",
        border: "none",
        borderRadius: "8px",
        padding: "8px 28px 8px 12px",
      },
      coloured: {
        border: "none",
        borderRadius: "28px !important",
      },
    },
  })};
  ${color}
  & > p {
    margin: 0;
    min-width: 40px;
    overflow: hidden;
  }
`;
export const DropdownScroll = styled("div")`
  max-height: 150px;
  overflow-y: auto;
  overflow-x: auto;
  ${layout}

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  }

  ${(props) =>
    variant({
      variants: {
        lighterGrey: {
          maxHeight: "250px",
        },
        white: {
          maxHeight: "250px",
        },
        coloured: {
          maxHeight: "100px",
        },
      },
    })}
`;
export const DropdownScrollInnerDiv = styled("div")`
  position: relative;
  width: 100%;
`;

export const DropDownList = styled("ul")`
  padding: 0;
  margin: 7px 0px 0px 0px;
  background: #ffffff;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  box-shadow: 2px 2px 4px hsl(0deg 0% 87% / 25%);
  // color: #D0004B;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  transform: translateY(103%);
  z-index: 1100;
  width: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const ListItem = styled("li")`
  position: relative;
  list-style: none;
  margin-bottom: 0.2em;
  padding: 5px 6px 3px 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  width: 100%;
  font-size: 14px;
  display: inline-flex;
  color: ${(props) => {
    if (props.isDisabled) {
      return "#989898";
    }
    if (props.active) {
      return "#D0004B";
    }
    return "#000000";
  }};

  align-self: stretch;

  & > p {
    margin-bottom: 0;
    width: 100%;
  }

  & > div {
    position: absolute;
    right: 5px;
    top: 25px;
    transform: translateY(-115%);
  }

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "none" : "#D9D9D9")};
   
    color: ${(props) =>
      props.isDisabled ? "#989898" : props.theme.colors.black};
  }

  ${(props) =>
    variant({
      variants: {
        outlined: {
          "&:hover": {
            background: "none",
            color: `${props.isDisabled ? "#989898" : "black !important"}`,
          },
        },
        lighterGrey: {
          padding: "7px 5px",
          "&:hover": {
            background: "#F0F2F5",
            color: `${props.isDisabled ? "#989898" : "black !important"}`,
          },
          "& p": {
            width: "72%",
          },
        },
        white: {
          padding: "7px 5px",
          "&:hover": {
            background: "#F0F2F5",
            color: props.isDisabled ? "#989898" : "black",
          },
          "& p": {
            width: "72%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        outlinedGreen: {
          "& p": {
            width: "90%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        outlinedGrey: {
          "& p": {
            width: "90%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        coloured: {
          "&:hover": {
            background: "#F0F2F5",
            color: `${props.isDisabled ? "#989898" : "black !important"}`,
          },
          "& p": {
            width: "90%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    })}
`;

const getColor = (props) => {
  if (props.isDisabled) {
    return "#989898";
  }
  if (props.active) {
    return "#D0004B";
  }
  return "#000000";
};

export const SecondaryListItem = styled("li")`
  position: relative;
  list-style: none;
  margin-bottom: 0.2em;
  margin-left: 5px;
  padding: 5px 6px 3px 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  width: 100%;
  font-size: 14px;
  display: inline-flex;
  color: ${(props) => getColor(props)};
  align-self: stretch;

  & > p {
    margin-bottom: 0;
    width: 100%;
  }

  & > div {
    position: absolute;
    right: 10px;
    top: 25px;
    transform: translateY(-115%);
  }

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "none" : "#D9D9D9")};
    border-radius: ${(props) => (props.isDisabled ? "none" : "8px 8px 0px 0px")}
    color: ${(props) =>
      props.isDisabled ? "#989898" : props.theme.colors.white};
  }

  ${(props) =>
    variant({
      variants: {
        outlined: {
          "&:hover": {
            background: "none",
            color: `${props.isDisabled ? "#989898" : "black !important"}`,
          },
        },
      },
    })}
`;

export const NoResultFoundText = styled("div")`
  text-align: center;
  color: #989898;
  padding: 20px;
  font-size: 16px;
`;

export const MarginRightTextP = styled("p")`
  margin-left: 15px;
`;

export const StyledSearch = styled("input")`
  border: none;
  outline: none;
  height: 20px;
  max-width: fit-content;
  padding-left: 0px;

  ${(props) =>
    variant({
      variants: {
        lighterGrey: {
          background: "#F0F2F5",
          color: "black !important",
        },
      },
    })}
`;

export const SelectedValue = styled("div")`
  ${(props) =>
    variant({
      variants: {
        lighterGrey: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "135px",
        },
        white: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        outlinedGreen: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        outlinedGrey: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        coloured: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    })}
`;
