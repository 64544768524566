import React from "react";
import Box from "../Box/Box";
import Label from "../Label/Label";
import Icon from "../Icon/Icon";
import usePageRange from "../../customHooks/usePageRange";

const Pagination = ({
  currentPage,
  onPageChange,
  totalCount,
  pageSize,
  pagesInView= Math.ceil(totalCount / pageSize),
}) => {
  const pageRange = usePageRange({
    currentPage,
    totalCount,
    pageSize,
    pagesInView,
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="28px"
      mt="16px"
    >
      <Icon
        iconName="Next"
        color="#69788C"
        border="1px solid #E2E2E2"
        width= "30px"
        justifyContent="center"
        height= "30px"
        borderRadius= "8px"
        padding="0px 5px"
        mr="19px"
        transform="rotate(180deg)"
        onClick={() => {
          if (currentPage > 1) {
            onPageChange(currentPage - 1);
          }
        }}
      />
      <Box display="flex" gridGap="4px">
        {pageRange.map((page) => (
          <Box
            p="3px 8px"
            borderRadius="8px"
            border= "1px solid #E2E2E2"
            width="32px"
            height="32px"
            bg={page === currentPage ? "#D0004B" : "#F5F5F5"}
            onClick={() => onPageChange(page)}
          >
            <Label
              fs="xs"
              fontWeight={600}
              justifyContent="center"
              color={page === currentPage ? "white" : "#1A1A1A"}
            >
              {page}
            </Label>
          </Box>
        ))}
      </Box>
      <Icon
        iconName="Next"
        color="#69788C"
        border="1px solid #E2E2E2"
        width= "30px"
        justifyContent="center"
        height= "30px"
        padding="0px 5px"
        borderRadius= "8px"
        ml="19px"
        onClick={() => {
          if (currentPage < Math.ceil(totalCount / pageSize)) {
            onPageChange(currentPage + 1);
          }
        }}
      />
    </Box>
  );
};

export default Pagination;
