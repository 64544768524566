export const FETCHING_STATUS = "FETCHING_STATUS";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const INITIAL_ONBOARDING_DETAILS = "INITIAL_ONBOARDING_DETAILS";
export const SET_ONBOARDING_DETAILS = "SET_ONBOARDING_DETAILS";

export const INITIAL_ONBOARDING_MEMBER_DETAILS = "INITIAL_ONBOARDING_MEMBER_DETAILS";
export const SET_ONBOARDING_MEMBER_DETAILS = "SET_ONBOARDING_MEMBER_DETAILS";

export const NEW_ONBOARD = "NEW_ONBOARD";
export const NEW_ONBOARD_SUCCESS = "NEW_ONBOARD_SUCCESS";
export const NEW_ONBOARD_FAILURE = "NEW_ONBOARD_FAILURE";

export const NEW_ONBOARD_MEMBER = "NEW_ONBOARD_MEMBER";
export const NEW_ONBOARD_MEMBER_SUCCESS = "NEW_ONBOARD_MEMBER_SUCCESS";
export const NEW_ONBOARD_MEMBER_FAILURE = "NEW_ONBOARD_MEMBER_FAILURE";

export const SET_QUERY_DETAILS = "SET_QUERY_DETAILS";
export const SET_QUERY_DETAILS_SUCCESS = "SET_QUERY_DETAILS_SUCCESS";

export const OTP_SENT = "OTP_SENT";
export const SET_OTP_SENT = "SET_OTP_SENT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const OTP_SENT_FAILURE = "OTP_SENT_FAILURE";
export const IS_AGENT_EXIST = "IS_AGENT_EXIST";
export const IS_CUSTOMER_EXIST = "IS_CUSTOMER_EXIST";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_SUCCESS_FOR_CUSTOMER = "VALIDATE_OTP_SUCCESS_FOR_CUSTOMER";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_USER = "SET_USER";
export const SET_AGENT = "SET_AGENT";
export const SET_CUSTOMER = "SET_CUSTOMER";

export const ATTEMPT_TO_ADMIN_LOGIN = "ATTEMPT_TO_ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";

export const FETCHING_BLOG_DETAILS_DATA = "FETCHING_BLOG_DETAILS_DATA";
export const FETCHING_BLOG_DETAILS_DATA_SUCCESS = "FETCHING_BLOG_DETAILS_DATA_SUCCESS";
export const FETCHING_BLOG_DETAILS_DATA_FAILURE = "FETCHING_BLOG_DETAILS_DATA_FAILURE";

export const FETCHING_BLOG_LIST_DATA = "FETCHING_BLOG_LIST_DATA";
export const FETCHING_BLOG_LIST_DATA_SUCCESS = "FETCHING_BLOG_LIST_DATA_SUCCESS";
export const FETCHING_BLOG_LIST_DATA_FAILURE = "FETCHING_BLOG_LIST_DATA_FAILURE";

export const FETCHING_CAMPAIGN_LIST_DATA = "FETCHING_CAMPAIGN_LIST_DATA";
export const FETCHING_CAMPAIGN_LIST_DATA_SUCCESS = "FETCHING_CAMPAIGN_LIST_DATA_SUCCESS";
export const FETCHING_CAMPAIGN_LIST_DATA_FAILURE = "FETCHING_CAMPAIGN_LIST_DATA_FAILURE";

export const FETCHING_PAYMENT_HISTORY_DATA = "FETCHING_PAYMENT_HISTORY_DATA";
export const FETCHING_PAYMENT_HISTORY_DATA_SUCCESS = "FETCHING_PAYMENT_HISTORY_DATA_SUCCESS";
export const FETCHING_PAYMENT_HISTORY_DATA_FAILURE = "FETCHING_PAYMENT_HISTORY_DATA_FAILURE";

export const FETCHING_PENDING_PAYMENT_HISTORY_DATA = "FETCHING_PENDING_PAYMENT_HISTORY_DATA";
export const FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS = "FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS";
export const FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE = "FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE";

export const FETCHING_NOTIFICATION_LIST_DATA = "FETCHING_NOTIFICATION_LIST_DATA";
export const FETCHING_NOTIFICATION_LIST_DATA_SUCCESS = "FETCHING_NOTIFICATION_LIST_DATA_SUCCESS";
export const FETCHING_NOTIFICATION_LIST_DATA_FAILURE = "FETCHING_NOTIFICATION_LIST_DATA_FAILURE";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const UPDATE_MEMBER_SUBSCRIPTION = "UPDATE_MEMBER_SUBSCRIPTION";
export const UPDATE_MEMBER_SUBSCRIPTION_SUCCESS = "UPDATE_MEMBER_SUBSCRIPTION_SUCCESS";
export const UPDATE_MEMBER_SUBSCRIPTION_FAILURE = "UPDATE_MEMBER_SUBSCRIPTION_FAILURE";

export const FETCH_ACTIVE_MOSQUES = "FETCH_ACTIVE_MOSQUES";
export const FETCH_ACTIVE_MOSQUE_PLANS = "FETCH_ACTIVE_MOSQUE_PLANS";

export const SET_ACTIVE_MEMBER = 'SET_ACTIVE_MEMBER';

export const SET_IS_ONBOARDED = 'SET_IS_ONBOARDED';
export const SET_IS_ONBOARDED_MEMBER = 'SET_IS_ONBOARDED_MEMBER';
