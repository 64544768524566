import React  from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "../../../../../components/Box";
import styled from "styled-components";
import { fetchBlogList } from "../../../customerAction";
import Button from "../../../../../components/Buttons";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import truncate from "lodash/truncate";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import useActiveData from "../../../../../customHooks/useActiveData";

const Container = styled(Box)`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 44px;
    font-weight: 700;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
    h3 {
      font-size: 20px;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const CardContainer = styled(Box)`
  margin-top: 44px;
  display: flex;
  grid-gap: 15px;
  @media (max-width: 767px) {
    overflow: scroll;
    padding-bottom: 42px;
    margin-top: 0px;
    
  }
`;
const Card = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
`;

const NoTextData = styled(Box)`
  padding: 120px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  height: 60px;
  margin-bottom: 100px;
  @media (max-width: 767px) {
    padding: 0px;
    font-size: 16px;
    text-align: center;
  }
  
`;

const HeaderFetchingDetailsBox = styled(Box)`
  background: #f3f3f3;
  width: 100%;
  height: 20px;
`;

const MosqueNews = () => {
  const websiteData = useActiveData();
  const dispatch = useDispatch();
  const { customer_id } = useParams();

  const activeMemberSubscription = useSelector(
    (state) => state.customerReducer.activeMemberSubscription
  );

  const is_fetching_blog_list = useSelector(
    (state) => state.customerReducer.is_fetching_blog_list
  );
  const blogList = useSelector((state) => state.customerReducer.blog_list);
  const blogCount = useSelector(
    (state) => state.customerReducer.total_blog_count
  );
  const blogPageNum = useSelector(
    (state) => state.customerReducer.blog_page_num
  );
  const blogPageSize = useSelector(
    (state) => state.customerReducer.blog_page_size
  );

  const onPageChange = (page) => {
    let input = {};
    input.customer_id = customer_id;
    input.pageNum = page;
    input.pageSize = blogPageSize;
    if (
        activeMemberSubscription?.entity_id &&
        activeMemberSubscription?.organisation_id
    ) {
      input.filters = {};
      input.filters.entity_id = activeMemberSubscription?.entity_id;
      input.filters.organisation_id = activeMemberSubscription?.organisation_id;
      dispatch(fetchBlogList(input));
    }
  };

  const renderDataCard = () => {
    if (is_fetching_blog_list) {
      return (
          <div className="row mobile-row">
            {Array(8)
            .fill(0)
            .map((item) => (
                <Box className="col-lg-3 mb-4">
                  <div>
                    <div className="blog-card customer_card">
                      <img
                          src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg"
                          alt=""
                          className="customer_img"
                      />
                      <Button
                          type="primary"
                          bg="#f3f3f3"
                          mt="18px"
                          mb="18px"
                          textColor="#D0004B"
                          borderRadius="6px"
                      >
                        <span style={{ color: "#D0004B" }}></span>
                      </Button>
                      <h4
                          style={{
                            color: "#1a1a1a",
                            wordBreak: "break-all",
                            height: "84px",
                          }}
                      >
                        <HeaderFetchingDetailsBox mb="8px" />
                        <HeaderFetchingDetailsBox mb="8px" />
                        <HeaderFetchingDetailsBox />
                      </h4>
                      <div className="blog-profile">
                        <img
                            src="https://ik.imagekit.io/rzlzf40okya/elixir/auth_im.svg"
                            alt=""
                            className="auth_img"
                        />
                        <span> <HeaderFetchingDetailsBox width="60px !important" /> </span>
                        <div className="border-right"></div>
                        <span> <HeaderFetchingDetailsBox width="80px !important" /> </span>
                      </div>
                    </div>
                  </div>
                </Box>
            ))}
          </div>
      );
    } else if (blogList?.length > 0) {
      return (
          <div className="row mobile-row">
            {blogList &&
                blogList.map((item) => (
                    <StyledLink
                        key={item?._id}
                        to={`/customer/${customer_id}/blogs/${item?.url}/info`}
                        className="col-lg-3 mb-4"
                    >
                      <div key={item._id}>
                        <div className="blog-card customer_card">
                          {!item.preview_image ? (
                              <img
                                  src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg"
                                  alt=""
                                  className="customer_img"
                              />
                          ) : (
                              <img
                                  src={item?.preview_image}
                                  alt=""
                                  className="customer_img"
                              />
                          )}
                          {/* <Button
                              type="primary"
                              bg="rgba(208, 0, 75, 0.08)"
                              mt="18px"
                              mb="18px"
                              textColor="#D0004B"
                              borderRadius="6px"
                          >
                            <span style={{ color: "#D0004B" }}>{item?.tags}</span>
                          </Button> */}
                          <Box display="flex" flexWrap="wrap">
                          {item?.tags.map((tag) => (
                          <Button
                              key={tag}
                              mt="18px"
                              mb="18px"
                              mr="4px"
                              bg="rgba(208, 0, 75, 0.08)"
                              textColor="#D0004B"
                              borderRadius="6px"
                          >
                            <span style={{ color: "#D0004B" }}>{tag}</span>
                          </Button>
                           ))}
                          </Box>
                          {/* <h4>{item?.heading}</h4> */}
                          <h4
                              style={{
                                color: "#1a1a1a",
                                wordBreak: "break-all",
                                height: "84px",
                              }}
                          >
                            {truncate(item?.heading, { length: 75 })}
                          </h4>
                          <div className="blog-profile">
                            {/* <img src={item?.profile} alt="img" className="auth_img"/> */}
                            {!item.author_image ? (
                                <img
                                    src="https://ik.imagekit.io/rzlzf40okya/elixir/auth_im.svg"
                                    alt=""
                                    className="auth_img"
                                />
                            ) : (
                                <img
                                    src={item?.author_image}
                                    alt=""
                                    className="auth_img"
                                />
                            )}
                            <span>{item?.author_name}</span>
                            <div className="border-right"></div>
                            <span>
                            {moment(new Date(item?.published_date)).format(
                                "DD MMM YYYY"
                            )}
                          </span>
                          </div>
                        </div>
                      </div>
                    </StyledLink>
                ))}
          </div>
      );
    } else {
      return (
          <NoTextData>
            {websiteData?.LOGGED_IN_PAGE_ZERO_NEWS_TEXT}
          </NoTextData>
      );
    }
  }
  return (
    <Container id="mosque_news">
      <h3>{websiteData?.LOGGED_IN_PAGE_MOSQUE_NEWS_HEADER_TEXT}</h3>
      <Box mt="60px" className="container">
        <div className="">
          {renderDataCard()}
        </div>
        {/* end-desktop */}
       
      </Box>
      {blogList?.length > 0 && (
        <Box mt="30px" mb="60px">
          <PaginationV2
            currentPage={blogPageNum}
            totalCount={blogCount}
            pageSize={blogPageSize}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </Container>
  );
};

export default MosqueNews;
