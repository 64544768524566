import HEADER_TYPES from "../constants/headerTypes";
import STORAGE_FILE_CATEGORIES from "../constants/storageFileCategories";
import useFileUpload from "./useFileUpload";

const getAcceptUploads = (mediaType) => {
  switch (mediaType) {
    case HEADER_TYPES.IMAGE:
      return ".png,.jpg,.jpeg,.svg";
    case HEADER_TYPES.VIDEO:
      return ".mp4,.3gpp";
    case HEADER_TYPES.DOCUMENT:
      return ".pdf";
    default:
      return null;
  }
};

const getFileTypeError = (mediaType) => {
  switch (mediaType) {
    case HEADER_TYPES.IMAGE:
      return "Image file should be in 'jpeg' or 'png' or 'svg' format.";
    case HEADER_TYPES.VIDEO:
      return "Video file should be in 'mp4' or '3gpp' format.";
    case HEADER_TYPES.AUDIO:
      return "Audio file should be in a valid format. Please contact support for more details.";
    case HEADER_TYPES.DOCUMENT:
      return "Document file should be in a valid format. Please contact support for more details.";
    default:
      return null;
  }
};

const getSizeExceededError = (mediaType) => {
  switch (mediaType) {
    case HEADER_TYPES.IMAGE:
      return "Image file size should be less than 5MBs";
    case HEADER_TYPES.VIDEO:
      return "Video file size should be less than 16MBs";
    case HEADER_TYPES.AUDIO:
      return "Audio file size should be less than 16MBs";
    case HEADER_TYPES.DOCUMENT:
      return "Document file size should be less than 100MBs";
    default:
      return null;
  }
};

const getSizeLimit = (mediaType) => {
  switch (mediaType) {
    case HEADER_TYPES.IMAGE:
      return 5;
    case HEADER_TYPES.VIDEO:
      return 16;
    case HEADER_TYPES.AUDIO:
      return 16;
    case HEADER_TYPES.DOCUMENT:
      return 100;
    default:
      return null;
  }
};

function useMediaUpload({ mediaType, onSuccess }) {
  return useFileUpload({
    accept: getAcceptUploads(mediaType),
    fileCategory: STORAGE_FILE_CATEGORIES.MESSAGE_TEMPLATE_MEDIA,
    withFBUpload: true,
    sizeLimitInMB: getSizeLimit(mediaType?.toUpperCase()),
    sizeExceededError: getSizeExceededError(mediaType?.toUpperCase()),
    fileTypeError: getFileTypeError(mediaType?.toUpperCase()),
    onSuccess,
  });
}

export default useMediaUpload;
