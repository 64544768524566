import React, { useState, useRef, useEffect } from "react";
import HeaderData from "../../../constants/CustomerHeader.json";
import CustomerHeaderMobile from "../../../constants/CustomerHeaderMobile.json";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Icon from "../../../components/Icon";
import DefaultModal from "../../../components/DefaultModal/DefaultModal";
import Box from "../../../components/Box";
import styled from "styled-components";
import useOutsideClick from "../../../customHooks/useOutsideClick";
import { useSelector, useDispatch } from "react-redux";
import { fetchNotification, fetchPendingPaymentHistory } from "../customerAction";
import useActiveData from "../../../customHooks/useActiveData";

const Container = styled(Box)`
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
`;
const DropDownBox = styled(Box)`
  border: 1px solid #0002;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  margin-top: 150px;
  display: block;
  background: #ffffff;
  width: 150px;
  right: 5%;
  z-index: 1;
  display: ${(props) => (props.data ? "block" : "none")};
`;

const ItemDropdown = styled(Box)`
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 8px;

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "none" : "#D9D9D9")};
    color: #ffffff;
  }
`;

const ProfileIm = styled(Box)`
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-left: 20px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const Header = () => {
  const websiteData = useActiveData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customer_id } = useParams();

  const btnRef = useRef();

  const [linkRefs, setLinRefs] = useState(HeaderData);
  const [dlinkRef, setDLinkRefs] = useState (CustomerHeaderMobile) 

  const [profileOpen, setProfileOpen] = useState(false);

  const user = useSelector((state) => state.customerReducer.user);

  const [showNotification, setShowNotification] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [isShown, setIsShown] = useState(false);


  const activeMemberSubscription = useSelector((state) => state.customerReducer.activeMemberSubscription);
  const pendingPaymentHistoryList = useSelector((state) => state.customerReducer.pendingPaymentHistoryList);
  const totalPendingPaymentHistoryCount = useSelector((state) => state.customerReducer.totalPendingPaymentHistoryCount);
  const notificationList = useSelector((state) => state.customerReducer.notification_list);
  const notificationCount = useSelector((state) => state.customerReducer.total_notification_count);
  const notificationPageSize = useSelector((state) => state.customerReducer.notification_page_size);

  
  useEffect(() => {
    if (customer_id) {
      dispatch(fetchPendingPaymentHistory({ customer_id }));
    }
  }, [])


  const setInitialValue = () => {
    setProfileOpen(false);
  };

  const ref = useOutsideClick(() => setInitialValue());
  // scroll-section
  // const scrollListener = () => {
  //   const payment = isScrolledIntoView(document.getElementById('payment-history'));
  //   const mosque = isScrolledIntoView(document.getElementById('mosque-news'));
  //   const help = isScrolledIntoView(document.getElementById('help-your-mosque'));

  //   if (payment && !dlinkRef[2].active) {
  //     let newDLinkRef = dlinkRef.map((link) => {
  //       link.active = false;
  //       return link;
  //     });
  //     newDLinkRef[2].activeDesktop = true;
  //     setDLinkRefs(newDLinkRef)
  //   } else if(mosque && ! payment && !dlinkRef[1].active){
  //     let newDLinkRef = dlinkRef.map((link) => {
  //       link.active = false;
  //       return link;
  //     });
  //     newDLinkRef[1].active = true;
  //     setDLinkRefs(newDLinkRef) 
  //   } else if(help && !dlinkRef[0].active){
  //     let newDLinkRef = dlinkRef.map((link) => {
  //       link.active = false;
  //       return link;
  //     });
  //     newDLinkRef[0].active = true;
  //     setDLinkRefs(newDLinkRef)
  //   }
  // }
 
 
  // const isScrolledIntoView = (el) => {
  //   var rect = el.getBoundingClientRect();
  //   var elemTop = rect.top;
  //   var elemBottom = rect.bottom;

  //   var isVisible = elemTop + 290 < window.innerHeight && elemBottom >= 0;
  //   return isVisible;
  // }
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollListener);

  //   return () => window.removeEventListener('scroll', scrollListener);
  // }, [])
  // end
  const active = (i) => {
    let newLinkRefs = linkRefs.map((link) => {
      link.active = false;
      return link;
    });
    linkRefs[i].active = true;
    setLinRefs(newLinkRefs);

    if (i === 0) {
      const categoryElement = document.getElementById(`help_mosque`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (i === 1) {
      const categoryElement = document.getElementById(`mosque_news`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const activeDesktop = (i) => {
    let newDLinkRef = dlinkRef.map((link) => {
      link.active = false;
      return link;
    });
    dlinkRef[i].active = true;
    setDLinkRefs(newDLinkRef);

    if (i === 0) {
      const categoryElement = document.getElementById(`help_mosque`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (i === 1) {
      const categoryElement = document.getElementById(`mosque_news`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };

  const handleClickNotification = () => {
    setShowNotification(true);
    setShowLogout(false);
  };

  const handleClickLogout = () => {
    setShowNotification(false);
    setShowLogout(true);
  };

  const handleDropdown = () => {
    setProfileOpen(!profileOpen);
  };

  const logoutHandle = () => {
    localStorage.removeItem("token");
    window.location = "/login";
  };

  useEffect(() => {
    let input = {};
    input.customer_id = customer_id;
    input.pageNum = 1;
    input.pageSize = notificationPageSize;
    dispatch(fetchNotification(input));
  }, [dispatch, customer_id]);

  const redirectHome = () => {
    navigate(`/customer/${user?._id}/home`);
  };

  return (
    <Container className="elixir-header-fixed" ref={ref}>
      <div className="for-mobile-menu">
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary menu-btn"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            <Icon iconName="Hamburger" iconSize="small" />
          </button>
          <div className="logo-div-mobile" onClick={() => redirectHome()}>
            <div className="text-center">
              <img src="/assets/Logo.png" alt=""/>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <img
                      src="https://ik.imagekit.io/zwhvovcr84s/svg-edited__1__eMXfDj2vV.svg?updatedAt=1683193496294"
                      alt=""
                      className="w-100"
                    />
                  </span>
                </button>
                <div className="profile-mobile">
                  <div className="pic"></div>
                  <div>
                    <div className="name">{user?.contact_name}</div>
                    <button
                        onClick={() => {
                          window.location.replace(`/customer/${user?._id}/info`);
                        }}
                    >Edit Profile</button>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <ul>
                  {linkRefs &&
                      linkRefs.map((item, i) => (
                      <li
                        onClick={() => active(i)}
                        className={item.active ? "activeClassHeader" : null}
                      >
                        <Link onClick={() => {window.location.replace(`/customer/${user?._id}/${item.link}`);}}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  <li onClick={() => handleClickLogout()}>
                    <Link>{websiteData?.LOGOUT_PAGE_LOGOUT_BTN_TEXT}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-div" onClick={() => redirectHome()}>
        <img src="/assets/Logo.png" alt=""/>
      </div>
      <div className="d-flex align-items-center">
        <div className="elixir-header-content jj">
          <ul>
            {CustomerHeaderMobile &&
                CustomerHeaderMobile.map((item, i) => (
                <li
                  onClick={() => activeDesktop(i)}
                  className={item.active ? "activeClassHeader" : null}
                >
                  <Link
                    to={`/customer/${user?._id}/${item.link}`}
                    style={{ fontsize: "18px" }}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div onClick={handleClick}>
          <div className="noti-msg" onClick={() => handleClickNotification()}>
            {!showNotification ? (
              <span className="notification">{notificationCount}</span>
            ) : (
              <Box width="21px" />
            )}
            {!showNotification ? (
              <Icon iconName="BellIcon" />
            ) : (
              <Box width="21px" />
            )}
          </div>
        </div>
        {
          (activeMemberSubscription?.payment_method === "manual" &&
            pendingPaymentHistoryList?.length > 0) &&
            <ul
                className="d-flex align-items-center mb-0"
                style={{ textDecoration: "none" }}
            >
              <li className="login_btn">
                <Link to={`/customer/${user?._id}/payment/${pendingPaymentHistoryList?.[0]?._id}/pay`}>Pay Now</Link>
              </li>
            </ul>
        }
        <ProfileIm onClick={handleDropdown}>
          {user?.profile_pic ? (
            <img
              src={user?.profile_pic}
              alt=""
              width="45px"
              height="45px"
              style={{ borderRadius: "50%" }}
            />
          ) : (
              <Box
                  width="40px"
                  height="40px"
                  borderRadius="50%"
                  bg="#D0004B"
              >
                <div className={"text-center"} style={{ paddingTop: "8px", color: "white" }}>{user && user.contact_name ? user?.contact_name[0] : ""}</div>
              </Box>
          )}
          <Icon iconName="arrowDown" />
        </ProfileIm>
        {/* <DropdownLink /> */}
        <DropDownBox data={profileOpen} ref={btnRef}>
          <ItemDropdown
            onClick={() => {
              window.location.replace(`/customer/${user?._id}/info`);
            }}
          >
            <h6>My Profile</h6>
          </ItemDropdown>
          <ItemDropdown
            onClick={() => {
              window.location.replace(`/customer/${user?._id}/plans`);
            }}
          >
            <h6>My Plan</h6>
          </ItemDropdown>
          <ItemDropdown onClick={() => handleClickLogout()}>
            <h6>Logout</h6>
          </ItemDropdown>
        </DropDownBox>
      </div>
      <DefaultModal
        show={showNotification}
        onHide={() => setShowNotification(false)}
        className="notification-modal-v1"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div
          className="noti-msg-white"
          onClick={() => setShowNotification(true)}
        >
          <span className="notification">{notificationCount}</span>
          <Icon iconName="BellIconWhite" />
        </div>
        <div className="noti-popup">
          <div className="d-flex justify-content-between noti-heading">
            <h5>Notifications</h5>
            <h6>Mark As Read</h6>
          </div>
          <div className="modal-scroll">
            {notificationList &&
              notificationList.map((item) => (
                <>
                  <div className="border-bottom mb-4 mt-3"></div>
                  <div className="msg-notification">
                    <span className="dot"></span>
                    <div className={item.profile}>{item.profileName}</div>
                    <div className="ml-4">
                      <p>
                        <b>{item.name} </b> {item.text}{" "}
                      </p>
                      <small>{item.days}</small>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </DefaultModal>
      <Modal
        show={showLogout}
        onHide={() => setShowLogout(false)}
        className="logout-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setShowLogout(false)}
            >
              <span aria-hidden="true">
                <Icon iconName="modalClose" />
              </span>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <Icon iconName="Logout" style={{ margin: "auto" }} />
            <div className="text">{websiteData?.LOGOUT_PAGE_TEXT}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowLogout(false)} className="cancel">
            {websiteData?.LOGOUT_PAGE_CANCEL_TEXT}
          </button>
          <button onClick={() => logoutHandle()} className="logout">
            {websiteData?.LOGOUT_PAGE_LOGOUT_BTN_TEXT}
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Header;
