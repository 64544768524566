import { useMemo } from "react";

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

const usePageRange = ({ currentPage, totalCount, pageSize, pagesInView }) =>
  useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    if (pagesInView >= totalPageCount) {
      return range(1, totalPageCount);
    }
    if (currentPage === totalPageCount) {
      return range(totalPageCount - (pagesInView - 1), totalPageCount);
    }
    if (currentPage >= pagesInView && currentPage + 1 <= totalPageCount) {
      return range(currentPage - (pagesInView - 2), currentPage + 1);
    }
    return range(1, pagesInView);
  }, [currentPage, totalCount, pageSize, pagesInView]);

export default usePageRange;
