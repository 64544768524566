import React, {useCallback, useState} from "react";
import styled from "styled-components";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";
import {callApiV2} from "../../../../../util/apiCaller";
import {showNotification} from "../../../../../util/Utility";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
  margin: 20px;
`;
const LoginModal = styled.div`
  width: 442px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

const DetailsBody = styled.div`
`;

const BodyContainer = ({setIsSuccess, isFailed, payment, customer, pendingPaymentHistoryList}) => {
    const [userDetails, setUserDetails] = useState({});
    const [paymentDetails, setPaymentInfo] = useState({});
    const [payment_mode, setPaymentMode] = useState("");
    const [resMessage, setResMessage] = useState(null);
    const [isNext, setIsNext] = useState(false);
    const [isApplied, setIsApplied] = useState(false);

    const handleProceedPayment = useCallback(async (payment_mode) => {
        let res = null;
        if (payment.payment_mode === "auto" && payment.payment_amount === 0) {
            res = await callApiV2({
                method: "post",
                endpoint: `payment/credorax/zero-payment?payment_id=${payment?._id}`,
            });
            window.location = `${res.data}`;
        } else {
            console.log("payment_mode", payment_mode);
            if (payment_mode === "credit") {
                res = await callApiV2({
                    method: "post",
                    endpoint: `payment/credorax/one-time-payment?payment_id=${payment?._id}`,
                });
                window.location = `${res.data}`;
            } else if (payment_mode === "ideal") {
                res = await callApiV2({
                    method: "post",
                    endpoint: `payment/credorax/one-time-alternate-payment?payment_id=${payment?._id}`,
                });
                if (res?.data?.redirect_urls?.redirect_url) {
                    window.location = `${res?.data?.redirect_urls?.redirect_url}`;
                } else {
                    showNotification('error', res?.data?.message.toString());
                }
            } else {
                showNotification('error', "Please select a payment mode");
            }
        }
    }, [payment])

    const handlePayment = useCallback(async () => {
        const res = await callApiV2({
            method: "get",
            endpoint: `customer/payment/${payment?._id}/confirm`,
        });
        setResMessage(res?.message);
        setIsSuccess(true);
    }, [payment])

    const handlePaymentMethod = (val) => {
        console.log("method", val);
        setPaymentMode(val);
    }

    return (
        <LoginTabContainer>
            <LoginModal>
                {isFailed ?
                    <>
                        <Box display="flex" flexDirection="column" alignItems="center" style={{marginBottom: '20px'}}>
                            <Label
                                color="#1A1A1A"
                                variant="text2"
                                fontWeight={700}
                                lineHeight="24px"
                            >
                                {"Payment Failed, Please try again!"}
                            </Label>
                        </Box>
                    </>
                    :
                    null
                }
                <DetailsBody>
                    <Box>
                        {customer?.contact_phone ?
                            <>
                                <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Registered Contact
                                    Number</Label>
                                <Input
                                    isDisabled={true}
                                    countryCodeValue={customer?.country_code}
                                    value={customer?.contact_phone}
                                    countryCodes={true}
                                    setCountryCode={(e) => setUserDetails("country_code", e)}
                                    onChange={(e) => setUserDetails({...userDetails, contact_phone: e.target.value})}
                                    border="1px solid #EFF0F7"
                                    boxShadow="0px 2px 6px #13124212"
                                    borderRadius="8px"
                                    placeholder=""
                                    className="input-box"
                                    maxWidth="442px"
                                />
                            </>
                            :
                            <>
                                <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Registered Contact
                                    Email</Label>
                                <Input
                                    isDisabled={true}
                                    countryCodeValue={customer?.country_code}
                                    value={customer?.contact_email}
                                    countryCodes={false}
                                    setCountryCode={(e) => setUserDetails("country_code", e)}
                                    onChange={(e) => setUserDetails({...userDetails, contact_email: e.target.value})}
                                    border="1px solid #EFF0F7"
                                    boxShadow="0px 2px 6px #13124212"
                                    borderRadius="8px"
                                    placeholder=""
                                    className="input-box"
                                    maxWidth="442px"
                                />
                            </>
                        }
                    </Box>
                    <Box mt="35px">
                        <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Member Fee</Label>
                        <Input
                            isDisabled={true}
                            value={payment?.payment_amount}
                            currencySymbols={true}
                            setCurrencySymbols={(e) => setPaymentInfo("currency_symbol", e)}
                            onChange={(e) => setPaymentInfo({...paymentDetails, payment_amount: e.target.value})}
                            border="1px solid #EFF0F7"
                            boxShadow="0px 2px 6px #13124212"
                            borderRadius="8px"
                            placeholder=""
                            className="input-box"
                            // width="300px"
                            maxWidth="442px"
                            pattern="[0-9]"
                        />
                    </Box>
                    <Box mt="35px">
                        <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Admin Fee</Label>
                        <Input
                            isDisabled={true}
                            value={payment?.payment_fee_cut}
                            currencySymbols={true}
                            setCurrencySymbols={(e) => setPaymentInfo("currency_symbol", e)}
                            // onChange={(e) => setPaymentInfo({...paymentDetails, payment_amount: e.target.value})}
                            border="1px solid #EFF0F7"
                            boxShadow="0px 2px 6px #13124212"
                            borderRadius="8px"
                            placeholder=""
                            className="input-box"
                            // width="300px"
                            maxWidth="442px"
                            pattern="[0-9]"
                        />
                    </Box>
                    <Box mt="35px">
                        <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Total Amount Due</Label>
                        <Input
                            isDisabled={true}
                            value={parseInt(payment?.payment_amount ? payment?.payment_amount : 0) + parseInt(payment?.payment_fee_cut ? payment?.payment_fee_cut : 0)}
                            currencySymbols={true}
                            setCurrencySymbols={(e) => setPaymentInfo("currency_symbol", e)}
                            // onChange={(e) => setPaymentInfo({...paymentDetails, payment_amount: e.target.value})}
                            border="1px solid #EFF0F7"
                            boxShadow="0px 2px 6px #13124212"
                            borderRadius="8px"
                            placeholder=""
                            className="input-box"
                            // width="300px"
                            maxWidth="442px"
                            pattern="[0-9]"
                        />
                    </Box>
                    {payment?.payment_mode === "manual" ?
                        <Box mt="35px">
                            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Payment Method</Label>
                            <input
                                onChange={(e) => handlePaymentMethod(e.target.value)}
                                checked={payment_mode === "ideal"}
                                type="radio" value={"ideal"}  name={"payment_method"}/> {"IDEAL"}
                            <input
                                onChange={(e) => handlePaymentMethod(e.target.value)}
                                checked={payment_mode === "credit"}
                                type="radio" value={"credit"} name={"payment_method"} /> {'Credit / Debit Card'}
                        </Box>:
                        null
                    }
                </DetailsBody>
                <Box display="flex" justifyContent="center" mt="30px">
                    <Button
                        onClick={() => handleProceedPayment(payment_mode)}
                        width="40px" minWidth="150px"
                        type={"primary"}>
                        Proceed To Pay
                    </Button>
                </Box>
            </LoginModal>
        </LoginTabContainer>
    );
}

export default BodyContainer;
