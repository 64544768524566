import React from "react";
import propTypes from "prop-types";
import Box from "../Box";

const iconSizeMap = {
  xl: "76px",
  "2large": "60px",
  large: "42px",
  normal: "36px",
  small: "24px",
  xs: "19px",
  "2xs": "16px",
  tiny: "13px",
  "2tiny": "10px",
};

const Radio = ({ checked, disabled, size, ...props }) => {
  const finalSize = iconSizeMap[size] || size;
  return (
    <Box
      height={finalSize}
      width={finalSize}
      border={`1px solid ${checked ? "#278351" : "#222222"}`}
      background="#fefefe"
      borderRadius="100%"
      cursor="pointer"
      className={`radio-button ${checked && "checked"}`}
    >
      {!!checked && (
        <Box
          background="#278351"
          width="60%"
          height="60%"
          borderRadius="100%"
          m="50% 0 0 50%"
          transform="translate(-50%, -50%)"
          className="radio-button-checked"
        />
      )}
    </Box>
  );
};

Radio.propTypes = {
  checked: propTypes.bool.isRequired,
  disabled: propTypes.bool,
  size: propTypes.string,
};

Radio.defaultProps = {
  disabled: false,
  size: "18px",
};
export default Radio;
