import React, { useEffect } from "react";
import queryString from "../../../../util/queryString";
import styled from "styled-components";
import Box from "../../../../components/Box";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DetailsInfo from "./components/DetailsInfo";

const Container = styled(Box)`
  height: auto;
  padding:60px 0px 0px;
  // overflow: scroll;
  background: #faf4fd;

  // &::-webkit-scrollbar {
  //   width: 0; /* Remove scrollbar space */
  //   background: transparent; /* Optional: just make scrollbar invisible */
  // }
`;


const AdminProfileBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 40px;
  .changePassword {
    font-weight: 500;
    font-size: 12px;
    color: #d0004b;
    text-align: right;
    margin: 15px 0;
  }
`;

function CustomerDetails() {
  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
    if (queryObj?.register === "true") {
      const categoryElement = document.getElementById(`onboarding-state`);
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <Box>
      <Header />
      <AdminProfileBox>
          <div className="row">
              <div className="col-md-4">
              </div>
              <div className="col-md-6">
                  <DetailsInfo />
              </div>
              <div className="col-md-4">
              </div>
          </div>
          <Footer />
      </AdminProfileBox>

    </Box>
  );
}

export default CustomerDetails;
