import {
    ADD_NEW_BLOG,
    FETCH_BLOG,
    FETCH_ORGANISATION_ENTITIES,
    FETCHING_BLOG,
    FETCHING_CUSTOMER_LIST,
    FETCHING_CUSTOMER_LIST_FAILURE,
    FETCHING_CUSTOMER_LIST_SUCCESS,
    FETCHING_MOSQUE_BLOG_LIST_DATA,
    FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE,
    FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS,
    FETCHING_STATUS,
    IS_AGENT_EXIST,
    LOAD_AGENT_DASHBOARD_STATS,
    LOAD_DASHBOARD_MONTH_ON_MONTH_STATS,
    LOAD_MEMBER_DETAILS,
    LOAD_MEMBER_PAYMENT_HISTORY,
    LOAD_MEMBER_REMINDER_HISTORY,
    LOADING_MEMBER_DETAILS,
    LOADING_MEMBER_PAYMENT_HISTORY,
    LOADING_MEMBER_REMINDER_HISTORY,
    OTP_SENT,
    OTP_SENT_FAILURE,
    OTP_SENT_SUCCESS,
    SAVE_EDIT_BLOG,
    SAVING_EDIT_BLOG,
    SENDING_REMINDER,
    SET_AGENT,
    SET_MEMBER_DETAILS_VISIBLE,
    FETCHING_CAMPAIGN_LIST,
    FETCHING_CAMPAIGN_LIST_FAILURE,
    FETCHING_CAMPAIGN_LIST_SUCCESS,
    ADD_NEW_CAMPAIGN,
    SAVING_CAMPAIGN,
    FETCHING_CAMPAIGN,
    FETCH_CAMPAIGN,
    SAVE_CAMPAIGN,
    FETCHING_PLAN_LIST,
    FETCHING_PLAN_LIST_FAILURE,
    FETCHING_PLAN_LIST_SUCCESS,
    UPDATE_ADDED_NEW_FLAG,
    SET_PLAN_DETAILS,
    ADD_NEW_PLAN,
    ADD_NEW_PLAN_FAILURE,
    ADD_NEW_PLAN_SUCCESS,
    FINANCIALS_STATS_DATA,
    FINANCIALS_STATS_DATA_FAILURE,
    FINANCIALS_STATS_DATA_SUCCESS,
    FETCH_MEMBER_SUBS_COUNT_DETAILS,
    FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE,
    FETCH_MEMBER_REVENUE_STATS_DETAILS,
    FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE,
    FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS,
    FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS,
    SET_BLOG_PAGE_NUM,
    UPDATE_AGENT,
    UPDATE_AGENT_FAILURE,
    UPDATE_AGENT_SUCCESS,
    FETCHING_ORG_DETAILS,
    FETCHING_ORG_DETAILS_FAILURE,
    FETCHING_ORG_DETAILS_SUCCESS,
    UPDATE_MEMBER_INFO_STATUS_SUCCESS,
    UPDATE_MEMBER_INFO_STATUS,
    UPDATE_MEMBER_INFO_STATUS_FAILURE,
    UPDATE_CUSTOMER_SUCCESS,
    FETCHING_AGENT_LIST_SUCCESS,
    FETCHING_AGENT_LIST,
    FETCHING_AGENT_LIST_FAILURE,
    SET_OTP_SENT,
    FETCHING_APPROVAL_LIST_SUCCESS,
    FETCHING_APPROVAL_LIST_FAILURE,
    FETCHING_APPROVAL_LIST,
    MEMBER_APPROVAL_STATUS,
    SET_UPDATED_STATUS, UPDATE_APPROVAL_STATUS, MEMBER_DECLINE_STATUS
} from "./mosqueActionType";
import { clone } from "lodash";

const initialState = {
    isFetching: false,
    is_agent_found: false,
    isOtpSent: false,
    agent: {},
    orgDetails: {},
    entities: [],
    activeEntity: {},
    dashboardStats: {},
    total_blog_count: 0,
    blog_list: [],
    blog_page_size: 6,
    blog_page_num: 1,
    is_fetching_blog_list: false,
    edit_blog: {},
    is_saving_blog: false,
    add_campaign: {},
    is_saving_campaign: false,
    memberPlans: {
        isFetching: false,
        planList: [],
        planListCount: 0,
        plan_details: {
            plan_name: "",
            amount: "",
            recurring_type: "",
            payment_day: "",
        },
        isAddedNew: false
    },
    customerInfo: {
        pageNum: 1,
        pageSize: 30,
        isFetching: false,
        customerInfoList: [],
        customerInfoCount: 0,
        payments: [],
        customerInfoPayments: {},
    },
    is_sending_reminder: false,
    monthOnMonthStats: [],
    activeMember: '',
    isMemberModalVisible: false,
    memberDetails: {
        isFetching: false,
        isUpdatedSuccessfully: false,
        isFetchingPaymentHistory: false,
        isFetchingReminderHistory: false,
        member: {},
        payment_history: [],
        reminder_history: []
    },
    total_campaign_count: 0,
    campaign_list: [],
    campaign_page_num: 1,
    campaign_page_size: 10,
    is_fetching_camapign_list: false,
    financialsStats: {},
    memberSubscriptionCount: {},
    memberRevenueStats: [],
    agentsList: [],
    totalAgentCount: 0,
    approvalList: [],
    approvalListCount: 0,
    isApprovedApproval: false,
    isApprovalDecline: false
};

const AdminReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_NEW_PLAN:
        case ADD_NEW_PLAN_FAILURE:
        case FINANCIALS_STATS_DATA:
        case FINANCIALS_STATS_DATA_FAILURE:
        case FETCH_MEMBER_SUBS_COUNT_DETAILS:
        case FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE:
        case FETCH_MEMBER_REVENUE_STATS_DETAILS:
        case FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE:
        case FETCHING_STATUS:
        case UPDATE_AGENT:
        case UPDATE_AGENT_FAILURE:
        case FETCHING_ORG_DETAILS:
        case FETCHING_ORG_DETAILS_FAILURE:
        case UPDATE_MEMBER_INFO_STATUS:
        case UPDATE_MEMBER_INFO_STATUS_FAILURE:
        case FETCHING_AGENT_LIST:
        case FETCHING_AGENT_LIST_FAILURE:
        case FETCHING_APPROVAL_LIST:
        case FETCHING_APPROVAL_LIST_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case MEMBER_APPROVAL_STATUS: {
            const listDetails = clone(state.approvalList);
            const newList = listDetails.filter(item => item._id !== action?.approvalId);

            return {
                ...state,
                approvalList: newList,
                approvalListCount: newList?.length,
                isApprovedApproval: true
            }
        }

        case MEMBER_DECLINE_STATUS: {
            const listDetails = clone(state.approvalList);
            const newList = listDetails.filter(item => item._id !== action?.approvalId);

            return {
                ...state,
                approvalList: newList,
                approvalListCount: newList?.length,
                isApprovalDecline: true
            }
        }

        case UPDATE_APPROVAL_STATUS:
            return {
                ...state,
                isApprovedApproval: action.isApprovedApproval
            }

        case FETCHING_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                approvalList: action.list,
                approvalListCount: action.count,
                isFetching: action.isFetching
            }

        case FETCHING_AGENT_LIST_SUCCESS:
            return {
                ...state,
                agentsList: action.agentList,
                totalAgentCount: action.agentCount,
                isFetching: action.isFetching
            }

        case FETCHING_ORG_DETAILS_SUCCESS:
            return {
                ...state,
                orgDetails: action.organisation
            }

        case UPDATE_AGENT_SUCCESS:
            const agentData = state.agentsList
            .map((data) => {
                let details = { ...data };
                if (data?._id === action?.details?._id) {
                    details.status = action?.details?.status;
                    details.membership = action?.details?.membership;
                    details.membership_date = action?.details?.membership_date;
                    details.role = action?.details?.role;
                    details.is_active = action?.details?.is_active;
                }
                return details;
            });

            return {
                ...state,
                isFetching: action.isFetching,
                agent: state.agent?._id === action.details?._id ? action.details : state.agent,
                agentsList: agentData
            }

        case SET_AGENT:
            return {
                ...state,
                agent: action.agent,
            };

        case OTP_SENT:
        case OTP_SENT_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case IS_AGENT_EXIST:
            return {
                ...state,
                is_agent_found: action.is_agent_found,
            };

        case SET_OTP_SENT:
            return {
                ...state,
                isOtpSent: action.status,
            };

        case OTP_SENT_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                isOtpSent: action.isOtpSent,
            };

        case FETCH_ORGANISATION_ENTITIES:
            return {
                ...state,
                entities: action.entities,
                activeEntity: action.entities && action.entities[0] ? action.entities[0] : {}
            };

        case LOAD_AGENT_DASHBOARD_STATS:
            return {
                ...state,
                dashboardStats: action.dashboardStats,
            };

        case FETCHING_PLAN_LIST:
        case FETCHING_PLAN_LIST_FAILURE:
            return {
                ...state,
                memberPlans: {
                    ...state.memberPlans,
                    isFetching: action.isFetching,
                },
            };

        case FETCHING_PLAN_LIST_SUCCESS:
            return {
                ...state,
                memberPlans: {
                    ...state.memberPlans,
                    isFetching: action.isFetching,
                    planList: action.planList,
                    planListCount: action.planListCount,
                },
            };

        case UPDATE_ADDED_NEW_FLAG:
            return {
                ...state,
                memberPlans: {
                    ...state.memberPlans,
                    isAddedNew: action.status,
                },
            };

        case SET_PLAN_DETAILS: {
            return {
                ...state,
                memberPlans: {
                    ...state.memberPlans,
                    plan_details: {
                        plan_name:
                            action.keyName === "plan_name"
                                ? action.keyValue
                                : state.memberPlans?.plan_details?.plan_name,
                        amount:
                            action.keyName === "amount"
                                ? action.keyValue
                                : state.memberPlans?.plan_details?.amount,
                        recurring_type:
                            action.keyName === "recurring_type"
                                ? action.keyValue
                                : state.memberPlans?.plan_details?.recurring_type,
                        payment_day:
                            action.keyName === "payment_day"
                                ? action.keyValue
                                : state.memberPlans?.plan_details?.payment_day,
                    },
                },
            };
        }

        case ADD_NEW_PLAN_SUCCESS:
            return {
                ...state,
                memberPlans: {
                    ...state.memberPlans,
                    isAddedNew: action.isAddedNew,
                    isFetching: action.isFetching,
                    plan_details: {
                        plan_name: "",
                        amount: "",
                        recurring_type: "",
                        payment_day: "",
                    },
                },
            };

        case FINANCIALS_STATS_DATA_SUCCESS:
            return {
                ...state,
                financialsStats: action.details
            }

        case FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS:
            return {
                ...state,
                memberSubscriptionCount: action.details
            }

        case FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS:
            return {
                ...state,
                memberRevenueStats: action.details
            }

        case FETCHING_MOSQUE_BLOG_LIST_DATA:
        case FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE:
            return {
                ...state,
                is_fetching_blog_list: action.is_fetching_blog_list,
            };

        case FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_blog_list: action.is_fetching_blog_list,
                blog_list: action.blog_list,
                blog_page_num: action.blog_page_num,
                blog_page_size: action.blog_page_size,
                total_blog_count: action.total_blog_count,
            };

        case SAVING_EDIT_BLOG:
            return {
                ...state,
                is_saving_blog: action.is_saving_blog,
            };

        case FETCHING_BLOG:
            return {
                ...state,
                is_saving_blog: action.is_saving_blog,
            };

        case FETCH_BLOG:
            return {
                ...state,
                edit_blog: action.blog,
            };

        case ADD_NEW_BLOG:
            let new_blog_list = [...state.blog_list];
            let total_blog_count = state.total_blog_count;
            if (new_blog_list) {
                new_blog_list.push(action.blog);
                total_blog_count += 1;
            }
            return {
                ...state,
                is_saving_blog: action.is_saving_blog,
                blog_list: new_blog_list,
                total_blog_count: total_blog_count,
            };

        case SAVE_EDIT_BLOG:
            let blog_list = state.blog_list.map(item => {
                if (item._id === action.blog._id) {
                    return action.blog;
                }
            })
            return {
                ...state,
                is_saving_blog: action.is_saving_blog,
                blog_list: blog_list,
            };

        case SENDING_REMINDER:
            return {
                ...state,
                is_sending_reminder: action.is_sending_reminder,
            };

        case FETCHING_CUSTOMER_LIST:
        case FETCHING_CUSTOMER_LIST_FAILURE:
            return {
                ...state,
                customerInfo: {
                    ...state.customerInfo,
                    isFetching: action.isFetching,
                },
            };

        case FETCHING_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                customerInfo: {
                    ...state.customerInfo,
                    isFetching: action.isFetching,
                    customerInfoList: action.list,
                    customerInfoCount: action.count,
                    payments: action.payments,
                    customerInfoPayments: action.customerPayments,
                    pageSize: action.pageSize,
                    pageNum: action.pageNum
                },
            };

        case LOAD_DASHBOARD_MONTH_ON_MONTH_STATS:
            return {
                ...state,
                monthOnMonthStats: action.monthOnMonthStats
            };
        case SET_MEMBER_DETAILS_VISIBLE:
            return {
                ...state,
                isMemberModalVisible: action.isMemberModalVisible,
                activeMember: action.activeMember,
                memberDetails: {
                    ...state.memberDetails,
                    reminder_history: []
                }
            };

        case LOADING_MEMBER_DETAILS:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    isFetching: action.isFetching
                }
            };

        case LOADING_MEMBER_PAYMENT_HISTORY:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    isFetchingPaymentHistory: action.isFetching,
                }
            };

        case LOADING_MEMBER_REMINDER_HISTORY:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    isFetchingReminderHistory: action.isFetching,
                }
            };

        case LOAD_MEMBER_DETAILS:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    customer: action.customer,
                    member: action.member,
                }
            };

        case LOAD_MEMBER_PAYMENT_HISTORY:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    payment_history: action.payment_history,
                    total_payment_count: action.total_payment_count,
                }
            };

        case LOAD_MEMBER_REMINDER_HISTORY:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    reminder_history: [...state.memberDetails?.reminder_history, ...action.reminder_history],
                    total_reminder_count: action.total_reminder_count,
                }
            };
            case FETCHING_CAMPAIGN_LIST:
                case FETCHING_CAMPAIGN_LIST_FAILURE:
                    return {
                        ...state,
                        is_fetching_camapign_list: action.is_fetching_camapign_list,
                    };

                case FETCHING_CAMPAIGN_LIST_SUCCESS:
                    return {
                        ...state,
                        is_fetching_camapign_list: action.is_fetching_camapign_list,
                        total_campaign_count: action.total_campaign_count,
                        campaign_list: action.campaign_list,
                        campaign_page_num : action.campaign_page_num,
                        campaign_page_size : action.campaign_page_size
                    };

                    case ADD_NEW_CAMPAIGN:
                        let new_campaign_list = [...state.campaign_list];
                        let total_campaign_count = state.total_campaign_count;
                        if (new_campaign_list) {
                            new_campaign_list.push(action.campaigns);
                            total_campaign_count += 1;
                        }
                        return {
                            ...state,
                            is_saving_campaign: action.is_saving_campaign,
                            campaign_list: new_campaign_list,
                            total_campaign_count: total_campaign_count,
                        };

        case SAVING_CAMPAIGN:
            return {
                ...state,
                is_saving_campaign: action.is_saving_campaign,
            };

        case FETCHING_CAMPAIGN:
            return {
                ...state,
                is_saving_campaign: action.is_saving_campaign,
            };

        case FETCH_CAMPAIGN:
            return {
                ...state,
                add_campaign: action.campaigns,
            };

        case SAVE_CAMPAIGN:
            let campaign_list = state.campaign_list.map(item => {
                if (item._id === action.campaigns._id) {
                    return action.campaigns;
                }
            })
            return {
                ...state,
                is_saving_campaign: action.is_saving_campaign,
                campaign_list: campaign_list,
            };
        case UPDATE_MEMBER_INFO_STATUS_SUCCESS:
            const updatedData = state.customerInfo?.customerInfoList
            .map((data) => {
                let details = { ...data };
                if (data?._id === action?.details?._id) {
                    details.is_active = action?.details?.is_active;
                    details.membership = action?.details?.membership;
                    details.member_since = action?.details?.member_since;
                }
                return details;
            });

            return {
                ...state,
                customerInfo: {
                    ...state.customerInfo,
                    customerInfoList: updatedData
                },
                activeMember: {
                    ...state.activeMember,
                    is_active: action.details?.is_active,
                    membership: action.details?.membership,
                    member_since: action.details?.member_since
                },
                isFetching: action?.isFetching
            };

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    isUpdatedSuccessfully: action.isUpdatedSuccessfully,
                    customer: {
                        ...state?.memberDetails?.customer,
                        address: action.details?.address
                    }
                }
            };

        case SET_UPDATED_STATUS:
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    isUpdatedSuccessfully: action.status
                }
            }

        default:
            return state;
    }
};

export default AdminReducer;
