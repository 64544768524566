import React, {useEffect, useState} from "react";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainer";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {callApiV2} from "../../../../util/apiCaller";
import {showNotification} from "../../../../util/Utility";
import {useDispatch, useSelector} from "react-redux";
import {fetchPendingPaymentHistory} from "../../customerAction";
import Button from "../../../../components/Buttons";
import {AUTHENTICATED} from "../../../../constants/authEnums";

const PayNow = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {customer_id, payment_id} = useParams();

    const authState = useSelector((state) => state?.appReducer?.authState);

    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [payment, setPayment] = useState(null);
    const [member, setMember] = useState(null);
    const [customer, setCustomer] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    // const pendingPaymentHistoryList = useSelector(
    //     (state) => state.customerReducer.pendingPaymentHistoryList
    // );
    //
    // const pendingPayment = pendingPaymentHistoryList?.[0];

    // useEffect(() => {
    //     if (customer_id) {
    //         dispatch(fetchPendingPaymentHistory({customer_id}));
    //     }
    // }, []);

    // useEffect(() => {
    //     if (customer_id && pendingPaymentHistoryList?.length > 0) {
    //         callApiCustomer();
    //     }
    // }, [customer_id, pendingPaymentHistoryList]);

    useEffect(() => {
        if (payment_id && customer_id) {
            callApiCustomerPayment(payment_id);
        }
    }, [customer_id, payment_id]);

    // const callApiCustomer = async () => {
    //     const res = await callApiV2({
    //         method: "get",
    //         endpoint: `customer/${pendingPayment?._id}/payment-info`,
    //     });
    //     if (res?.status === "Success") {
    //         if (res?.data?.payment) {
    //             setPayment(res?.data?.payment);
    //         }
    //         if (res?.data?.customer) {
    //             setCustomer(res?.data?.customer);
    //         } else if (authState) {
    //             const resCustomer = await callApiV2({
    //                 method: "get",
    //                 endpoint: `customer/${customer_id}`,
    //             });
    //             if (resCustomer?.status === "Success") {
    //                 if (resCustomer?.data?.customer) {
    //                     setCustomer(resCustomer?.data?.customer);
    //                 }
    //             }
    //         }
    //     } else {
    //         showNotification("error", "Something went wrong...");
    //     }
    // };

    const callApiCustomerPayment = async (payment_id) => {
        const res = await callApiV2({
            method: "get",
            endpoint: `customer/${payment_id}/payment-info`,
        });
        if (res?.status === "Success") {
            if (res?.data?.payment) {
                setPayment(res?.data?.payment);
                if (res?.data?.payment.payment_status === "failed") {
                    console.log("here");
                    setIsFailed(true);
                }
            }
            if (res?.data?.customer) {
                setCustomer(res?.data?.customer);
            } else if (authState === AUTHENTICATED) {
                const resCustomer = await callApiV2({
                    method: "get",
                    endpoint: `customer/${customer_id}`,
                });
                if (resCustomer?.status === "Success") {
                    if (resCustomer?.data?.customer) {
                        setCustomer(resCustomer?.data?.customer);
                    }
                }
            }
        } else {
            showNotification("error", "Something went wrong...");
        }
    };

    useEffect(() => {
        let payment_method = searchParams.get("payment");
        if (payment_method === "success") {
            setIsSuccess(true);
        } else if (payment_method === "failed") {
            setIsFailed(true);
        }
    }, [searchParams]);

    const getPaymentText = (payment) => {
        if (payment) {
            if (payment.payment_mode === "manual") {
                return `Welcome To Mosqapp. The following payment is a credit payment or iDeal payment. Your payment is successful.`;
            } else if (payment.payment_mode === "auto") {
                return `Welcome To Mosqapp. Your zero charge payment is successful. You will be charged automatically the due payment amount`;
            }
        }
    }

    return (
        <div>
            <Banner
                isSuccess={isSuccess}
                customerId={customer_id} payment={payment}/>

            {payment?.payment_status === "paid" || isSuccess ? (
                <ResponseContainer>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Label
                            color="#1A1A1A"
                            variant="text2"
                            fontWeight={700}
                            lineHeight="24px"
                        >
                            {isSuccess ? "Payment Successful" : ""}
                        </Label>
                        <Label
                            color="#7E7E7E"
                            variant="text5"
                            fontWeight={500}
                            lineHeight="24px"
                            mt="16px"
                        >
                            {getPaymentText(payment)}
                        </Label>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="30px">
                        {payment?.source === "member-onboarding" ?
                            <Button
                                onClick={() => navigate(`/?onboarding=member&msid=${payment.member_subscription_id}&payment=${payment.payment_status}&register=true&asMember=true`)}
                                width="40px" minWidth="150px"
                                type={"primary"}>
                                Return to Home
                            </Button>:
                            <Button
                                onClick={() => navigate(`/`)}
                                width="40px" minWidth="150px"
                                type={"primary"}>
                                Return to Home
                            </Button>
                        }
                    </Box>
                </ResponseContainer>
            ) : (
                <BodyContainer
                    setIsSuccess={setIsSuccess}
                    isFailed={isFailed}
                    payment={payment}
                    customer={customer}
                />
            )}
        </div>
    );
};

export default PayNow;
