import React from "react";
import styled from "styled-components";
import {
  space,
  layout,
} from "styled-system";

const Loader = styled.div`
  border: 2px solid #004C73;
  border-radius: 50%;
  border-top: 2px solid #ffffff00;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  ${space}
  ${layout}
`;

const OnlyLoader = ({size, ...props}) => {
  return (
    <div className="text-center" data-cy="only-loader">
      <Loader {...props} width={size ? size : '16px'} height={size ? size : '16px'} />
    </div>
  );
}

export default OnlyLoader;
