import React from 'react'
import useActiveData from "../../../../../customHooks/useActiveData";

function OurFeatures() {
    const websiteData = useActiveData();

    return (
        <div className='feature-sec'>
            <div className='container'>
                <div className="apart-head">
                    <h2 className="heading-h1">
                        {websiteData.HOME_PAGE_FEATURE_SEGMENT_TEXT}
                    </h2>
                    <p>
                        {websiteData.HOME_PAGE_FEATURE_SEGMENT_SUB_TEXT}
                    </p>
                </div>
                <div className='d-deskotop'>
                    <div className='d-flex'>
                        <div className='left-track'>
                            <div className='first-track'>
                                <img src="assets/DonationTracking.svg"/>
                                <h4>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_FIRST_TEXT}
                                </h4>
                                <p> {websiteData.HOME_PAGE_FEATURE_SEGMENT_FIRST_SUB_TEXT}</p>

                            </div>
                            <div className='secnd-track'>
                                <img src="assets/DataSecurityPrivacy.svg"/>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_FOURTH_TEXT}</h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_FOURTH_SUB_TEXT}
                                </p>

                            </div>
                        </div>
                        <div className='feature-bg'>
                            <img src="assets/featuresB.svg"/>
                        </div>
                        <div className='right-track'>
                            <div className='third-track'>
                                <img src="assets/DonorManagement.svg"/>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_SECOND_TEXT}</h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_SECOND_SUB_TEXT}
                                </p>

                            </div>
                            <div className='fourth-track'>
                                <img src="assets/male-telemarketer.svg"/>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_THIRD_TEXT}</h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_THIRD_SUB_TEXT}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-mobile'>
                    <div className='feature-bg'>
                        <img src="assets/featuresB.svg"/>
                    </div>
                    <div className='left-track'>
                        <div className='first-track'>
                            <img src="assets/DonationTracking.svg"/>
                            <div>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_FIRST_TEXT}</h4>
                                <p> {websiteData.HOME_PAGE_FEATURE_SEGMENT_FIRST_SUB_TEXT}</p>
                            </div>


                        </div>
                        <div className='secnd-track'>
                            <img src="assets/DataSecurityPrivacy.svg"/>
                            <div>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_FOURTH_TEXT}</h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_FOURTH_SUB_TEXT}
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className='right-track'>
                        <div className='third-track'>
                            <img src="assets/DonorManagement.svg"/>
                            <div>
                                <h4>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_SECOND_TEXT}
                                </h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_SECOND_SUB_TEXT}
                                </p>
                            </div>
                        </div>
                        <div className='fourth-track'>
                            <img src="assets/male-telemarketer.svg"/>
                            <div>
                                <h4> {websiteData.HOME_PAGE_FEATURE_SEGMENT_THIRD_TEXT}</h4>
                                <p>
                                    {websiteData.HOME_PAGE_FEATURE_SEGMENT_THIRD_SUB_TEXT}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurFeatures