import {
    ADMIN_LOGIN_FAILED,
    ADMIN_LOGIN_SUCCESS,
    ATTEMPT_TO_ADMIN_LOGIN,
    FETCH_ACTIVE_MOSQUE_PLANS,
    FETCH_ACTIVE_MOSQUES,
    FETCHING_BLOG_DETAILS_DATA,
    FETCHING_BLOG_DETAILS_DATA_FAILURE,
    FETCHING_BLOG_DETAILS_DATA_SUCCESS,
    FETCHING_BLOG_LIST_DATA,
    FETCHING_BLOG_LIST_DATA_FAILURE,
    FETCHING_BLOG_LIST_DATA_SUCCESS,
    FETCHING_CAMPAIGN_LIST_DATA,
    FETCHING_CAMPAIGN_LIST_DATA_FAILURE,
    FETCHING_CAMPAIGN_LIST_DATA_SUCCESS,
    FETCHING_NOTIFICATION_LIST_DATA,
    FETCHING_NOTIFICATION_LIST_DATA_FAILURE,
    FETCHING_NOTIFICATION_LIST_DATA_SUCCESS,
    FETCHING_PAYMENT_HISTORY_DATA,
    FETCHING_PAYMENT_HISTORY_DATA_FAILURE,
    FETCHING_PAYMENT_HISTORY_DATA_SUCCESS,
    FETCHING_STATUS,
    IS_AGENT_EXIST,
    IS_CUSTOMER_EXIST,
    NEW_ONBOARD,
    NEW_ONBOARD_FAILURE,
    NEW_ONBOARD_MEMBER_SUCCESS,
    NEW_ONBOARD_SUCCESS,
    OTP_SENT,
    OTP_SENT_FAILURE,
    OTP_SENT_SUCCESS,
    SET_ACTIVE_MEMBER,
    SET_AGENT,
    SET_AUTH_STATE,
    SET_CUSTOMER,
    SET_ONBOARDING_DETAILS,
    SET_ONBOARDING_MEMBER_DETAILS,
    SET_OTP_SENT,
    SET_QUERY_DETAILS,
    SET_QUERY_DETAILS_SUCCESS,
    SET_USER,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_FAILURE,
    UPDATE_CUSTOMER_SUCCESS,
    VALIDATE_OTP,
    VALIDATE_OTP_FAILURE,
    VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_SUCCESS_FOR_CUSTOMER,
    SET_IS_ONBOARDED,
    SET_IS_ONBOARDED_MEMBER,
    INITIAL_ONBOARDING_DETAILS,
    INITIAL_ONBOARDING_MEMBER_DETAILS,
    UPDATE_MEMBER_SUBSCRIPTION,
    UPDATE_MEMBER_SUBSCRIPTION_FAILURE,
    UPDATE_MEMBER_SUBSCRIPTION_SUCCESS,
    FETCHING_PENDING_PAYMENT_HISTORY_DATA,
    FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE, FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS, SET_LANGUAGE
} from "./customerActionType";
import {NOT_INITIALIZED} from "../../constants/authEnums";

const initialState = {
    authState: NOT_INITIALIZED,
    isFetching: false,
    onboardingDetails: {
        entity_name: "",
        entity_address: "",
        entity_pincode: "",
        entity_country: "",
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        country_code: "+31",
        is_whatsapp_opted: true,
        isAppliedTnC: false,
    },
    is_error: false,
    error_message: "",
    is_onboarding: false,
    is_onboarded: false,
    memberDetails: {
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        country_code: "+31",
        address: "",
        subscription_plan_id: "",
        entity_id: "",
        payment_method: "",
        is_whatsapp_opted: true,
        isAppliedTnC: false,
    },
    is_onboarding_member: false,
    is_onboarded_member: false,
    contact_us: {
        contact_name: "",
        contact_email: "",
        message_query: "",
    },
    is_fetching_blog_list: false,
    blog_list: [],
    total_blog_count: 0,
    blog_page_num: 1,
    blog_page_size: 12,
    is_fetching_campaign_list: false,
    campaign_list: [],
    total_campaign_count: 0,
    campaign_page_num: 1,
    campaign_page_size: 12,
    is_fetching_blog: false,
    blog: {},
    is_fetching_payment_history_list: false,
    payment_history_list: [],
    total_payment_history_count: 0,
    payment_history_page_num: 1,
    payment_history_page_size: 10,
    is_fetching_notification_list: false,
    notification_list: [],
    total_notification_count: 0,
    notification_page_num: 1,
    notification_page_size: 10,
    is_agent_found: false,
    is_customer_found: false,
    isOtpSent: false,
    otp_error: "",
    isValidate: false,
    isLoggingIn: false,
    isAuthenticated: false,
    user: {},
    agent: {},
    customer: {},
    activeMemberSubscription: {},
    activeMember: {},
    mosqueData: [],
    mosqueList: [],
    activeMosquePlans: [],
    pendingPaymentHistoryList: [],
    totalPendingPaymentHistoryCount: 0,
    language_code: "en"
};

const CustomerReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_LANGUAGE:
            return {
                ...state,
                language_code: action.code
            }

        case FETCHING_STATUS:
        case UPDATE_MEMBER_SUBSCRIPTION:
        case UPDATE_MEMBER_SUBSCRIPTION_FAILURE:
        case FETCHING_PENDING_PAYMENT_HISTORY_DATA:
        case FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case UPDATE_MEMBER_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                activeMemberSubscription: action.details,
            };

        case SET_AUTH_STATE:
            return {
                ...state,
                authState: action.authState,
            };

        case SET_USER:
            return {
                ...state,
                user: action.user,
            };

        case SET_AGENT:
            return {
                ...state,
                agent: action.agent,
            };

        case SET_OTP_SENT:
            return {
                ...state,
                isOtpSent: action.status,
            };

        case SET_IS_ONBOARDED:
            return {
                ...state,
                is_onboarded: action.status,
            };

        case SET_IS_ONBOARDED_MEMBER:
            return {
                ...state,
                is_onboarded_member: action.status,
            };

        case INITIAL_ONBOARDING_DETAILS:
            return {
                ...state,
                onboardingDetails: {
                    entity_name: "",
                    entity_address: "",
                    entity_pincode: "",
                    entity_country: "",
                    contact_name: "",
                    contact_email: "",
                    contact_phone: "",
                    country_code: "+31",
                    is_whatsapp_opted: true,
                    isAppliedTnC: false,
                }
            }

        case SET_ONBOARDING_DETAILS: {
            return {
                ...state,
                onboardingDetails: {
                    ...state.onboardingDetails,
                    entity_name:
                        action.keyName === "entity_name"
                            ? action.keyValue
                            : state.onboardingDetails?.entity_name,
                    entity_address:
                        action.keyName === "entity_address"
                            ? action.keyValue
                            : state.onboardingDetails?.entity_address,
                    entity_pincode:
                        action.keyName === "entity_pincode"
                            ? action.keyValue
                            : state.onboardingDetails?.entity_pincode,
                    entity_country:
                        action.keyName === "entity_country"
                            ? action.keyValue
                            : state.onboardingDetails?.entity_country,
                    contact_name:
                        action.keyName === "contact_name"
                            ? action.keyValue
                            : state.onboardingDetails?.contact_name,
                    contact_email:
                        action.keyName === "contact_email"
                            ? action.keyValue
                            : state.onboardingDetails?.contact_email,
                    contact_phone:
                        action.keyName === "contact_phone"
                            ? action.keyValue
                            : state.onboardingDetails?.contact_phone,
                    country_code:
                        action.keyName === "country_code"
                            ? action.keyValue
                            : state.onboardingDetails?.country_code,
                    billing_plan:
                        action.keyName === "billing_plan"
                            ? action.keyValue
                            : state.onboardingDetails?.billing_plan,
                    is_whatsapp_opted:
                        action.keyName === "is_whatsapp_opted"
                            ? action.keyValue
                            : state.onboardingDetails?.is_whatsapp_opted,
                    isAppliedTnC:
                        action.keyName === "isAppliedTnC"
                            ? action.keyValue
                            : state.onboardingDetails?.isAppliedTnC,
                },
            };
        }

        case INITIAL_ONBOARDING_MEMBER_DETAILS:
            return {
                ...state,
                memberDetails: {
                    contact_name: "",
                    contact_email: "",
                    contact_phone: "",
                    country_code: "+31",
                    address: "",
                    subscription_plan_id: "",
                    entity_id: "",
                    payment_method: "",
                    is_whatsapp_opted: true,
                    isAppliedTnC: false,
                }
            }

        case SET_ONBOARDING_MEMBER_DETAILS: {
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    contact_name:
                        action.keyName === "contact_name"
                            ? action.keyValue
                            : state.memberDetails?.contact_name,
                    contact_email:
                        action.keyName === "contact_email"
                            ? action.keyValue
                            : state.memberDetails?.contact_email,
                    contact_phone:
                        action.keyName === "contact_phone"
                            ? action.keyValue
                            : state.memberDetails?.contact_phone,
                    country_code:
                        action.keyName === "country_code"
                            ? action.keyValue
                            : state.memberDetails?.country_code,
                    address:
                        action.keyName === "address"
                            ? action.keyValue
                            : state.memberDetails?.address,
                    subscription_plan_id:
                        action.keyName === "subscription_plan_id"
                            ? action.keyValue
                            : state.memberDetails?.subscription_plan_id,
                    entity_id:
                        action.keyName === "entity_id"
                            ? action.keyValue
                            : state.memberDetails?.entity_id,
                    payment_method:
                        action.keyName === "payment_method"
                            ? action.keyValue
                            : state.memberDetails?.payment_method,
                    is_whatsapp_opted:
                        action.keyName === "is_whatsapp_opted"
                            ? action.keyValue
                            : state.memberDetails?.is_whatsapp_opted,
                    isAppliedTnC:
                        action.keyName === "isAppliedTnC"
                            ? action.keyValue
                            : state.memberDetails?.isAppliedTnC,
                },
            };
        }

        case NEW_ONBOARD:
            return {
                ...state,
                is_onboarding: action.is_onboarding,
                is_error: action.is_error,
                error_message: action.error_message,
            };

        case NEW_ONBOARD_SUCCESS:
            return {
                ...state,
                is_onboarding: action.is_onboarding,
                is_onboarded: action.is_onboarded,
                onboardingDetails: {
                    entity_name: "",
                    entity_address: "",
                    entity_pincode: "",
                    entity_country: "",
                    contact_name: "",
                    contact_email: "",
                    contact_phone: "",
                    country_code: "+31",
                    is_whatsapp_opted: true,
                    isAppliedTnC: false,
                },
            };

        case NEW_ONBOARD_FAILURE:
            return {
                ...state,
                is_onboarding: action.is_onboarding,
                is_error: action.is_error,
                error_message: action.error_message,
            };

        case SET_QUERY_DETAILS: {
            return {
                ...state,
                contact_us: {
                    ...state.contact_us,
                    contact_name:
                        action.keyName === "contact_name"
                            ? action.keyValue
                            : state.contact_us?.contact_name,
                    contact_email:
                        action.keyName === "contact_email"
                            ? action.keyValue
                            : state.contact_us?.contact_email,
                    message_query:
                        action.keyName === "message_query"
                            ? action.keyValue
                            : state.contact_us?.message_query,
                },
            };
        }

        case SET_QUERY_DETAILS_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                contact_us: {
                    contact_name: "",
                    contact_email: "",
                    message_query: "",
                },
            };

        case OTP_SENT:
        case OTP_SENT_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case IS_AGENT_EXIST:
            return {
                ...state,
                is_agent_found: action.is_agent_found,
            };


        case IS_CUSTOMER_EXIST:
            return {
                ...state,
                is_customer_found: action.is_customer_found,
            };

        case OTP_SENT_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                isOtpSent: action.isOtpSent,
            };

        case VALIDATE_OTP:
            return {
                ...state,
                isFetching: action.isFetching,
                otp_error: ""
            };

        case VALIDATE_OTP_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
                otp_error: action?.otp_error ?? ""
            };

        case VALIDATE_OTP_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                isValidate: action.isValidate,
                user: action.payload ? action.payload?.agent : {},
            };

        case VALIDATE_OTP_SUCCESS_FOR_CUSTOMER:
            return {
                ...state,
                isFetching: action.isFetching,
                isValidate: action.isValidate,
                user: action.payload ? action.payload?.customer : {},
            };

        case ATTEMPT_TO_ADMIN_LOGIN:
        case ADMIN_LOGIN_FAILED:
            return {
                ...state,
                isLoggingIn: action.isLoggingIn,
            };

        case ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: action.isLoggingIn,
                isAuthenticated: action.isAuthenticated,
            };

        case FETCHING_BLOG_DETAILS_DATA:
        case FETCHING_BLOG_DETAILS_DATA_FAILURE:
            return {
                ...state,
                is_fetching_blog: action.is_fetching_blog
            };
        case FETCHING_BLOG_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_blog_list: action.is_fetching_blog_list,
                blog: action.blog,
            };

        case FETCHING_BLOG_LIST_DATA:
        case FETCHING_BLOG_LIST_DATA_FAILURE:
            return {
                ...state,
                is_fetching_blog_list: action.is_fetching_blog_list
            };
        case FETCHING_BLOG_LIST_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_blog_list: action.is_fetching_blog_list,
                total_blog_count: action.total_blog_count,
                blog_list: action.blog_list,
                blog_page_num: action.blog_page_num,
                blog_page_size: action.blog_page_size
            };

        case FETCHING_CAMPAIGN_LIST_DATA:
        case FETCHING_CAMPAIGN_LIST_DATA_FAILURE:
            return {
                ...state,
                is_fetching_campaign_list: action.is_fetching_campaign_list,
            };
        case FETCHING_CAMPAIGN_LIST_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_campaign_list: action.is_fetching_campaign_list,
                total_campaign_count: action.total_campaign_count,
                campaign_list: action.campaign_list,
                campaign_page_num: action.campaign_page_num,
                campaign_page_size: action.campaign_page_size
            };

        case FETCHING_PAYMENT_HISTORY_DATA:
        case FETCHING_PAYMENT_HISTORY_DATA_FAILURE:
            return {
                ...state,
                is_fetching_payment_history_list: action.is_fetching_payment_history_list
            };

        case FETCHING_PAYMENT_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_payment_history_list: action.is_fetching_payment_history_list,
                total_payment_history_count: action.total_payment_history_count,
                payment_history_list: action.payment_history_list,
                payment_history_page_num: action.payment_history_page_num,
                payment_history_page_size: action.payment_history_page_size
            };

        case FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                pendingPaymentHistoryList: action.payment_history_list,
                totalPendingPaymentHistoryCount: action.total_payment_history_count,
            };

        case FETCHING_NOTIFICATION_LIST_DATA:
        case FETCHING_NOTIFICATION_LIST_DATA_FAILURE:
            return {
                ...state,
                is_fetching_notification_list: action.is_fetching_notification_list
            };
        case FETCHING_NOTIFICATION_LIST_DATA_SUCCESS:
            return {
                ...state,
                is_fetching_notification_list: action.is_fetching_notification_list,
                total_notification_count: action.total_notification_count,
                notification_list: action.notification_list,
                notification_page_num: action.notification_page_num,
                notification_page_size: action.notification_page_size
            };

        case UPDATE_CUSTOMER:
        case UPDATE_CUSTOMER_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching
            };

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                isFetching: action.isFetching,
                user: action.details?.customer,
            };

        case FETCH_ACTIVE_MOSQUES:
            return {
                ...state,
                mosqueList: action.mosqueList,
            };

        case FETCH_ACTIVE_MOSQUE_PLANS:
            return {
                ...state,
                activeMosquePlans: action.activeMosquePlans,
            };

        case SET_ACTIVE_MEMBER:
            return {
                ...state,
                activeMember: action.member,
                activeMemberSubscription: action.member_subscription,
            };

        case NEW_ONBOARD_MEMBER_SUCCESS:
            return {
                ...state,
                is_onboarded_member: action.is_onboarded_member,
                memberDetails: {
                    contact_name: "",
                    contact_email: "",
                    contact_phone: "",
                    country_code: "+31",
                    address: "",
                    subscription_plan_id: "",
                    payment_method: "",
                    is_whatsapp_opted: true,
                    isAppliedTnC: false,
                },
            };
        default:
            return state;
    }
};

export default CustomerReducer;
