import React, {useEffect, useState} from "react";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Button from "../../../../../components/Buttons";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useParams, Link} from "react-router-dom";
import {fetchCampaignList} from "../../../customerAction";
import Slider from "react-slick";
import useActiveData from "../../../../../customHooks/useActiveData";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3{
    font-size:44px;
    font-weight:700;
    padding-top:100px;
  }
  @media (max-width: 767px) {
    h3{
    font-size:20px;
    padding-top:80px;
  }
  }
`;

const CardContainer = styled(Box)`
  margin-top: 44px;
  display: flex;
  grid-gap: 34px;
  justify-content:center;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    overflow: scroll;
    padding-bottom: 42px;
  }
`;
const Card = styled(Box)`
  // height: 420px;
  background: #FFFFFF;
  border: 1px solid #EFF0F7;
  // box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
`;
const Text = styled(Box)`
  margin-top: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  word-wrap: break-word;
  width: 250px;
  line-height: 28px;
  color: rgb(26, 26, 26);
  min-height: 80px;
`;

const DonateLink = styled(Link)`
  text-decoration: none;
`;

const ProgressBarCustom = styled.div`
  background: #d3edea;
  border-radius: 80px;
  width: 100%;
  height: 10px;
  margin: 10px 0 20px 0;
`;
const ProgressStatus = styled(Box)`
  background: #00ac4f;
  border-radius: 80px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .statusPointer {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: #d0004b;
    border-radius: 50%;
    border: 4px solid #ffffff;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);
  }
`;

const TickMarkIcon = styled.span`
  display: inline-block;
  font-size: 20px;
  color: #00ac4f;
`;

const LinkCopiedMessage = styled(Label)`
  background-color: #E9FFF3;
  padding: 2px;
  border-radius: 8px;
  margin-top: 5px;
`;

const NoTextData = styled(Box)`
  padding: 149px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  height: 60px;
  @media (max-width: 767px) {
    padding: 0px;
    font-size: 16px;
    text-align: center;
  }
`;

const HeaderFetchingDetailsBox = styled(Box)`
  background: #f3f3f3;
  width: 100%;
  height: 20px;
`;

const HelpMosqueCard = () => {
    const websiteData = useActiveData();
    const dispatch = useDispatch();
    const {customer_id} = useParams();

    const activeMemberSubscription = useSelector((state) => state.customerReducer.activeMemberSubscription);
    const campaignList = useSelector((state) => state.customerReducer.campaign_list);
    const campaignPageSize = useSelector((state) => state.customerReducer.campaign_page_size);
    const is_fetching_campaign_list = useSelector((state) => state.customerReducer.is_fetching_campaign_list);
    const [copiedLink, setCopiedLink] = useState(null);

    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: campaignList?.length < 4 ? campaignList?.length : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    };

    useEffect(() => {
        let input = {};
        input.customer_id = customer_id;
        input.pageNum = 1;
        input.pageSize = campaignPageSize;
        if (activeMemberSubscription?.entity_id && activeMemberSubscription?.organisation_id) {
            input.filters = {};
            input.filters.entity_id = activeMemberSubscription?.entity_id;
            input.filters.organisation_id = activeMemberSubscription?.organisation_id;
            dispatch(fetchCampaignList(input));
        }
    }, [dispatch, customer_id, activeMemberSubscription]);

    const copyLink = (link) => {
      const tempInput = document.createElement("input");
      tempInput.value = link;
      document.body.appendChild(tempInput);
      tempInput.select();
      tempInput.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setCopiedLink(link);
      setTimeout(() => {
        setCopiedLink(null);
      }, 3000);
    };

    const renderGrowth = (cur, prev) => {
        let percentage = 0;
        if (prev !== 0) {
            percentage = (prev / cur) * 100;
        }
        return Math.abs(percentage || 0).toFixed(0);
    }
    
    const renderDataCard = () => {
      if (is_fetching_campaign_list) {
        return (
            <div className="row">
              {Array(4)
              .fill(0)
              .map((item) => (
                  <Box className="col-lg-3 mb-4">
                    <div>
                      <div className="blog-card customer_card">
                        <img
                            src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg"
                            alt=""
                            className="customer_img"
                        />
                        <h4
                            style={{
                              color: "#1a1a1a",
                              wordBreak: "break-all",
                              height: "84px",
                            }}
                        >
                          <HeaderFetchingDetailsBox mb="8px" />
                          <HeaderFetchingDetailsBox mb="8px" />
                          <HeaderFetchingDetailsBox />
                        </h4>
                        <div>
                        <span> <HeaderFetchingDetailsBox width="80px !important" /> </span>
                        </div>
                        <span> <HeaderFetchingDetailsBox mt="5px"/> </span>
                        <div className="blog-profile">
                        <Button
                          type="primary"
                          bg="#f3f3f3"
                          mt="18px"
                          mb="18px"
                          textColor="#D0004B"
                          borderRadius="6px"
                      >
                        <span style={{ color: "#D0004B" }}></span>
                      </Button>
                          {/* <div className="border-right"></div> */}
                          <Button
                          type="primary"
                          bg="#f3f3f3"
                          mt="18px"
                          mb="18px"
                          ml="10px"
                          textColor="#D0004B"
                          borderRadius="6px"
                      >
                        <span style={{ color: "#D0004B" }}></span>
                      </Button>
                        </div>
                      </div>
                    </div>
                  </Box>
              ))}
            </div>
        );
      } else if (campaignList?.length > 0) {
        return (
            campaignList?.length > 4 ?
                <Slider {...settings}>
                    {campaignList?.map(item => (
                        <Card key={item?._id} >

                            {!item.img_url ? (
                                <img src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg" alt="" className="customer_img" />
                            ) : (
                                <img src={item?.img_url} alt={item?.img_url_alt} className="customer_img" />
                            )}
                            <Text>{item?.title}</Text>


                            <Box mt="6px" display="flex">
                                <Label fontSize="12px" fontWeight={700} color="#1A1A1A">{item?.currency ? item?.currency : "€"}{item?.total_amount_paid ? item?.total_amount_paid : '0'}</Label>
                                <Label fontSize="12px" fontWeight={700} color="#7E7E7E"> raised out of {item?.currency ? item?.currency : "€"}{item?.targetAmount}</Label>
                            </Box>
                            <Box mt="15px" mb="20px">
                                <div className="progress_skill">
                                    <Box className="skill1 html" width={`${renderGrowth(item?.targetAmount, item?.total_amount_paid)}%`}>
                                        <img
                                            src="https://ik.imagekit.io/rzlzf40okya/elixir/dot.svg"
                                            className="skill_im"
                                        />
                                    </Box>
                                </div>
                            </Box>
                            <Box mt="10px" display="flex" justifyContent="center">
                                <Button type="outlineColor" width="44px" mr="8px" onClick={() => copyLink(item?.title)}>Share Link</Button>
                                <DonateLink to={`/customer/${customer_id}/campaign/${item?._id}/donation`}>
                                    <Button width="44px">Donate Now</Button>
                                </DonateLink>
                            </Box>

                        </Card>
                    ))}
                </Slider>
                :
              
                <CardContainer>
                {campaignList?.map(item => (
                    <Card key={item?._id} >

                        {!item.img_url ? (
                            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg" alt="" className="customer_img" />
                        ) : (
                            <img src={item?.img_url} alt={item?.img_url_alt} className="customer_img" />
                        )}
                        <Text>{item?.title}</Text>


                        <Box mt="6px" display="flex">
                            <Label fontSize="12px" fontWeight={700} color="#1A1A1A">{item?.currency ? item?.currency : "€"}{item?.total_amount_paid ? item?.total_amount_paid : '0'}</Label>
                            <Label fontSize="12px" fontWeight={700} color="#7E7E7E"> raised out of {item?.currency ? item?.currency : "€"}{item?.targetAmount}</Label>
                        </Box>
                        <Box mt="15px" mb="20px">
                            <div className="progress_skill">
                                <Box className="skill1 html" width={`${renderGrowth(item?.targetAmount, item?.total_amount_paid)}%`}>
                                    <img
                                        src="https://ik.imagekit.io/rzlzf40okya/elixir/dot.svg"
                                        className="skill_im"
                                    />
                                </Box>
                            </div>
                            </Box>
                            <Box mt="10px" display="flex">
                                <Button type="outlineColor" width="44px" mr="8px" onClick={() => copyLink(item?.title)}>Share Link</Button>
                                <DonateLink to={`/customer/${customer_id}/campaign/${item?._id}/donation`}>
                                    <Button width="44px">Donate Now</Button>
                                </DonateLink>
                            </Box>
                       
                    </Card>
                ))}
            </CardContainer>
            // :
            // <NoTextData>
            //     You are upto date. No "news" from your mosque
            // </NoTextData>
        );
      } else {
        return (
            <NoTextData>
                {websiteData?.LOGGED_IN_PAGE_ZERO_CAMPAIGNS_TEXT}
            </NoTextData>
        );
      }
    }

    return (
        <Container className="onboarding-bg mosque-bg"  id="help_mosque">
            <h3>{websiteData?.LOGGED_IN_PAGE_HEADER_TEXT}</h3>
            <div className="container">
              {renderDataCard()}
            {/* <CardContainer> */}
            </div>
            {copiedLink && (
       <LinkCopiedMessage color="#00ac4f">
       <TickMarkIcon>&#10003;</TickMarkIcon> Link copied! You can share it
     </LinkCopiedMessage>
      )}

        </Container>

    );
};

export default HelpMosqueCard;
