import React, { useEffect } from "react";
import Box from "../../../../../components/Box";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentHistory } from "../../../customerAction";
import { useParams, Link } from "react-router-dom";
import moment from 'moment';
import Table from "../../../../../components/Table/Table";
import styled from "styled-components";
import useActiveData from "../../../../../customHooks/useActiveData";

const PayNowButton = styled(Box)`
  background: #d0004b !important;
  border-radius: 25px;
  margin-top: -5px;
  text-align: center;
  height: 35px;
  padding: 6px !important;
`;

const HistoryDetails = () => {
    const websiteData = useActiveData();
  const dispatch = useDispatch();
  const { customer_id } = useParams();

  const paymentHistoryList = useSelector((state) => state.customerReducer.payment_history_list);
  const paymentHistoryCount = useSelector((state) => state.customerReducer.total_payment_history_count);
  const paymentHistoryPageNum = useSelector((state) => state.customerReducer.payment_history_page_num);
  const paymentHistoryPageSize = useSelector((state) => state.customerReducer.payment_history_page_size);

  useEffect(() => {
    let input = {};
    input.customer_id = customer_id;
    input.pageNum = 1;
    input.pageSize = paymentHistoryPageSize;
    dispatch(fetchPaymentHistory(input));
  }, [dispatch, customer_id]);

  const onPageChange = (page) => {
    let input = {};
    input.customer_id = customer_id;
    input.pageNum = page;
    input.pageSize = paymentHistoryPageSize;
    dispatch(fetchPaymentHistory(input));
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return '#00AC4F';
      case 'pending':
        return '#FECB17';
      case 'cancelled':
        return '#FE1717';
      default:
        return '';
    }
  };

  return (
    <Box className="new-contact payment-footer" mb="50px" mt="40px">
      <h2 className="payment-header">{websiteData?.PAYMENT_HISTORY_PAGE_HEADER_TEXT}</h2>
      <div className="contact-box payment-box p-0">
       <div className="desktop-history">
        <div className="table">
          <div className="table-row">
            <div className="table-col">S.No.</div>
            <div className="table-col">Date</div>
            <div className="table-col">Payment I’d</div>
            <div className="table-col">Amount</div>
            <div className="table-col">Status</div>
            <div className="table-col">Action</div>
          </div>
            {paymentHistoryCount === 0 ? (
                <div className="payment-history-table">
              <Table
                gridTemplateColumnsHeader="5% 15% 17% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
                gridTemplateColumnsBody="5% 15% 18% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
                data={getPaymentStatusColor()}
              />
              </div>
            ) : (
              paymentHistoryList &&
              paymentHistoryList.map((item, index) => (
                <div className="table-row table_body">
                  <div
                    className="table-col font-class">{(paymentHistoryPageNum - 1) * paymentHistoryPageSize + index + 1}</div>
                  <div
                    className="table-col font-class">{new moment(item?.payment_due_date).format("DD MMM YYYY")}</div>
                  <div className="table-col font-class">{item?.payment_id ? item?.payment_id : 'NA'}</div>
                  <div className="table-col font-class">{item?.payment_amount ? item?.payment_amount : "NA"}</div>
                  <div className="table-col font-class" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="status-circle"
                      style={{
                        backgroundColor: getPaymentStatusColor(item?.payment_status),
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '8px',
                      }}>
                    </div>
                    {item?.payment_status}</div>
                  <div className="table-col font-class">
                    {
                      item?.payment_status === "paid" ?
                          <a href={item?.payment_link} className="pdf-btn">
                            <i className="fa fa-download" aria-hidden="true"></i>
                          </a>
                          :
                          <PayNowButton>
                            <Link to={`/customer/${customer_id}/payment/${item?._id}/pay`} style={{ fontSize: "14px", color: "#fff" }}>Pay Now</Link>
                          </PayNowButton>
                    }
                  </div>
                  {item.status}
                </div>
              ))
            )}
        </div>
        </div>
        {/* mobile */}
        <div className="mobile-history">
        <div className="table">
          <div className="table-row">
            <div className="table-col">S.No.</div>
            <div className="table-col">Date</div>
            <div className="table-col">Payment I’d</div>
            <div className="table-col">Amt</div>
            <div className="table-col">Status</div>

          </div>
            {paymentHistoryCount === 0 ? (
                <div className="payment-history-table">
              <Table
                gridTemplateColumnsHeader="5% 15% 17% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
                gridTemplateColumnsBody="5% 15% 18% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
                data={getPaymentStatusColor()}
              />
              </div>
            ) : (
              paymentHistoryList &&
              paymentHistoryList.map((item, index) => (
                <div className="table-row table_body">
                  <div
                    className="table-col font-class">{(paymentHistoryPageNum - 1) * paymentHistoryPageSize + index + 1}</div>
                  <div
                    className="table-col font-class">{new moment(item?.payment_due_date).format("DD MMM YYYY")}</div>
                  <div className="table-col font-class">{item?.payment_id ? item?.payment_id : 'NA'}</div>
                  <div className="table-col font-class">{item?.payment_amount ? item?.payment_amount : "NA"}</div>
                  <div className="table-col font-class" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="status-circle"
                      style={{
                        backgroundColor: getPaymentStatusColor(item?.payment_status),
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '8px',
                      }}>
                    </div>
                    {item?.payment_status}</div>
                  {item.status}
                </div>
              ))
            )}
        </div>
        </div>
      </div>
      <Box mt="30px" mb="60px">
        <Pagination
          currentPage={paymentHistoryPageNum}
          totalCount={paymentHistoryCount}
          pageSize={paymentHistoryPageSize}
          onPageChange={onPageChange} />
      </Box>
    </Box>
  );
}

export default HistoryDetails;

