import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./css/header.css";
import "./css/login.css";
import "./css/admin.css";
import "./css/blog.css";
import "./css/modal.css";
import "./css/addmember.css";
import "./css/antd.css";

import LoginPage from "./modules/Customer/pages/LoginPage/LoginPage";
import HomePage from "./modules/Customer/pages/NewHomePage/HomePage";

//Customer
import CustomerHome from "./modules/Customer/pages/CustomerHome/HomePage";
import CustomerDonation from "./modules/Customer/pages/DonationPage/DonationPage";
import CustomerBlogDetails from "./modules/Customer/pages/Blogs/BlogDetailsPage";
import CustomerContactUs from "./modules/Customer/pages/ContactUs/ContactUsPage";
import CustomerInfo from "./modules/Customer/pages/CustomerDetails/CustomerDetails";
import CustomerPayments from "./modules/Customer/pages/PaymentHistory/PaymentHistory";
import CustomerPlan from "./modules/Customer/pages/Plans/PlanPage";
import CustomerPayNow from "./modules/Customer/pages/PayNow/PayNow";

import RedirectPageApp from "./modules/Mosque/pages/RedirectPage/RedirectPage";

import { ToastContainer } from "react-toastify";
import { fetchCustomerProfile } from "./modules/Customer/customerAction";
import {
  setAuthentication,
  setAuthState,
  setUserId,
  setUserType,
} from "./modules/appAction";

import {
  AUTHENTICATED,
  AUTHENTICATING,
  NOT_AUTHENTICATED,
} from "./constants/authEnums";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import {
  APP_TOKEN,
  USER_ID,
  USER_TYPE,
  USER_TYPE_CUSTOMER,
} from "./constants/appEnums";
import IdleTimer from "./components/IdleTimer";

function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem(APP_TOKEN);
  const userType = localStorage.getItem(USER_TYPE);
  const userId = localStorage.getItem(USER_ID);

  const authState = useSelector((state) => state?.appReducer?.authState);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    localStorage.removeItem("_expiredTime");
    window.location.href = "/login";
  }

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 600, //expire after 10 seconds
      onTimeout: () => {
        handleLogOut();
      },
      onExpired: () => {
        // do something if expired on load
        handleLogOut();
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    try {
      dispatch(setAuthState(AUTHENTICATING));
      // get user from the payload of jwt token
      if (token) {
        dispatch(setAuthState(AUTHENTICATED));
        dispatch(setAuthentication(true));
        dispatch(setUserId(userId));
        dispatch(setUserType(userType));
        if (userType === USER_TYPE_CUSTOMER) {
          dispatch(fetchCustomerProfile(userId));
        }
      } else {
        dispatch(setAuthState(NOT_AUTHENTICATED));
        dispatch(setAuthentication(false));
      }
    } catch (err) {
      dispatch(setAuthState(NOT_AUTHENTICATED));
      dispatch(setAuthentication(false));
    }
  }, []);

  const AuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path={"login"} element={<RedirectPageApp />} />
          {/* Customer */}
          <Route path={"customer"}>
            <Route
              path={":customer_id/home"}
              element={<CustomerHome />}
            ></Route>
            <Route
              path={":customer_id/campaign/:campaign_id/donation"}
              element={<CustomerDonation />}
            ></Route>
            <Route
              path={":customer_id/contact-us"}
              element={<CustomerContactUs />}
            ></Route>
            <Route
              path={":customer_id/blogs/:url/info"}
              element={<CustomerBlogDetails />}
            ></Route>
            <Route
              path={":customer_id/payments"}
              element={<CustomerPayments />}
            ></Route>
            <Route
              path={":customer_id/info"}
              element={<CustomerInfo />}
            ></Route>
            <Route
              path={":customer_id/plans"}
              element={<CustomerPlan />}
            ></Route>
            <Route
              path={":pay_id/pay"}
              element={<CustomerPayNow />}
            ></Route>
            <Route
              path={":customer_id/payment/:payment_id/pay"}
              element={<CustomerPayNow />}
            ></Route>
          </Route>
        </Routes>
      </Router>
    );
  };

  const NotAuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path={"/customer/:customer_id/home"}
            element={<CustomerHome />}
          ></Route>
          <Route
            path={"/customer/:customer_id/payment/:payment_id/pay"}
            element={<CustomerPayNow />}
          ></Route>
          <Route path={":customer_id/info"} element={<CustomerInfo />}></Route>
          <Route
            path={":customer_id/contact-us"}
            element={<CustomerContactUs />}
          ></Route>
        </Routes>
      </Router>
    );
  };

  const getRoutes = () => {
    switch (authState) {
      case AUTHENTICATED:
        return <AuthenticatedRoutes />;
      case NOT_AUTHENTICATED:
        return <NotAuthenticatedRoutes />;
      case AUTHENTICATING:
        return <FullPageLoader />;
      default:
        return <FullPageLoader />;
    }
  };

  return (
    <React.Fragment>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Suspense fallback={<FullPageLoader />}>{getRoutes()}</Suspense>
        </ThemeProvider>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default App;
