import React from "react";
import OnboardingTab from "./components/OnboardingTab";
import "../../../../css/home.css";
import ErrorMsg from "./components/ErrorMsg";
import {useSelector} from "react-redux";
import useActiveData from "../../../../customHooks/useActiveData";

const Onboarded = () => {
    const websiteData = useActiveData();
    const is_error = useSelector((state) => state.customerReducer.is_error);
    const error_message = useSelector(
        (state) => state.customerReducer.error_message
    );

    return (
        <div className="onboarding-bg" id="onboarding-state">
            <h2>{websiteData?.HOME_PAGE_THIRD_SEGMENT_HEADER_TEXT}</h2>
            <div className="tab_bg">
                <OnboardingTab/>
            </div>
            {is_error && <ErrorMsg messageText={error_message}/>}
        </div>
    );
};

export default Onboarded;
