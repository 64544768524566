import React, {useEffect} from "react";
import queryString from "../../../../util/queryString";
import styled from "styled-components";
import Box from "../../../../components/Box";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DetailsPage from "./components/DetailsPage";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchBlogDetails} from "../../customerAction";

const Container = styled(Box)`
  height: auto;
//   overflow-y: scroll;
  background: #faf4fd;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

`;

function BlogDetailsPage() {
    const {customer_id, url} = useParams();
    const dispatch = useDispatch();

    const blog = useSelector((state) => state.customerReducer?.blog);


    useEffect(() => {
        const queryObj = queryString.parse(window.location.search);
        if (queryObj?.register === "true") {
            const categoryElement = document.getElementById(`onboarding-state`);
            categoryElement.scrollIntoView({behavior: "smooth"});
        }
    });

    useEffect(() => {
        dispatch(fetchBlogDetails({customer_id, url}));
    }, [dispatch])

    return (
        <Box>
            <Header/>
            <Container>
                <DetailsPage
                    blog={blog}
                    customer_id={customer_id}
                />
                <Footer/>
            </Container>
        </Box>
    );
}

export default BlogDetailsPage;
