import {
    IS_AGENT_EXIST,
    OTP_SENT,
    OTP_SENT_FAILURE,
    OTP_SENT_SUCCESS,
    SET_AGENT,
    VALIDATE_OTP,
    VALIDATE_OTP_FAILURE,
    VALIDATE_OTP_SUCCESS,
    FETCHING_PLAN_LIST,
    FETCHING_PLAN_LIST_SUCCESS,
    FETCHING_PLAN_LIST_FAILURE,
    SET_OTP_SENT,
} from "./mosqueActionType";
import {callApiV2} from "../../util/apiCaller";
import {APP_TOKEN, USER_ID, USER_TYPE, USER_TYPE_MOSQUE} from "../../constants/appEnums";
import jwt_decode from "jwt-decode";
import {AUTHENTICATED} from "../../constants/authEnums";
import {showNotification} from "../../util/Utility";
import {setAuthState, setUserId} from "../appAction";

export const setAgent = (agent) => ({
    type: SET_AGENT,
    agent,
});

export const setOtpSent = (status) => ({
    type: SET_OTP_SENT,
    status,
});

export const agentLogin = (body, isResend = false) => async (dispatch) => {
    try {
        dispatch({
            type: OTP_SENT,
            isFetching: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `agent/auth/login-otp`,
            body: {agent: body},
            isErrorSuppressed: true
        });

        if (res?.status === "Success") {
            if (isResend) {
                showNotification('success', "Resend OTP");
            }
            if (!!res?.data?.is_agent_found) {
                dispatch({
                    type: OTP_SENT_SUCCESS,
                    isOtpSent: true,
                    isFetching: false,
                });
            } else if (!res?.data?.is_agent_found) {
                dispatch({
                    type: IS_AGENT_EXIST,
                    is_agent_found: true,
                });
            }
        } else {
            showNotification("error", res?.data?.message?.message || res?.data?.message || "Something went wrong...");
        }
    } catch (err) {
        dispatch({
            type: OTP_SENT_FAILURE,
            isFetching: false,
        });
    }
};

export const validateOtpForAgent = (body) => async (dispatch) => {
    try {
        dispatch({
            type: VALIDATE_OTP,
            isFetching: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `agent/auth/validate-otp`,
            body: {agent: body},
        });

        if (res?.status === "Success") {
            const {token} = res?.data;
            window.localStorage.setItem(APP_TOKEN, token);
            window.localStorage.setItem(USER_TYPE, USER_TYPE_MOSQUE);
            window.localStorage.setItem(USER_ID, res?.data?.agent?._id);
            const tempUser = jwt_decode(token);
            dispatch(setAgent(tempUser));
            dispatch(setAuthState(AUTHENTICATED));

            showNotification("success", "Welcome to elixir admin dashboard.")
            dispatch({
                type: VALIDATE_OTP_SUCCESS,
                payload: res?.data,
                isValidate: true,
                isFetching: false,
            });

            if (res?.data?.agent?._id) {
                setUserId(res?.data?.agent?._id);
            }

            window.location = `/mosque/${res?.data?.agent?._id}/dashboard`;
        }
    } catch (err) {
        dispatch({
            type: VALIDATE_OTP_FAILURE,
            isFetching: false,
        });
    }
};

export const fetchPlanList = (entity_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_PLAN_LIST,
            isFetching: true
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `subscription_plan/list`,
            body: {
                filters: {
                    entity_id
                },
                pageNum: 1,
                pageSize: 50
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_PLAN_LIST_SUCCESS,
                planList: res?.data?.subscriptionPlanList || [],
                planListCount: res?.data?.subscriptionPlanCount || 0,
                isFetching: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_PLAN_LIST_FAILURE,
                isFetching: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong..");
        dispatch({
            type: FETCHING_PLAN_LIST_FAILURE,
            isFetching: false,
        });
    }
};
