import React, {useEffect, useState} from "react";
import UserLogin from "./UserLogin";
import { useSelector, useDispatch } from "react-redux";
import {
    USER_TYPE_CUSTOMER,
    USER_TYPE_MOSQUE,
} from "../../../../../constants/appEnums";
import { IS_AGENT_EXIST } from "../../../../Mosque/mosqueActionType";
import { IS_CUSTOMER_EXIST, SET_OTP_SENT } from "../../../customerActionType";
import { setOtpSent } from "../../../../Mosque/mosqueAction";
import useActiveData from "../../../../../customHooks/useActiveData";

function LoginTab() {
    const websiteData = useActiveData();
    const dispatch = useDispatch();
    const [selectedSegment, setSelectedSegment] = useState("Screen details");

    const isAuthenticated = useSelector(
        (state) => state.appReducer.isAuthenticated
    );
    const userType = useSelector((state) => state.appReducer.userType);
    const userId = useSelector((state) => state.appReducer.activeUserId);

    useEffect(() => {
        if (isAuthenticated && userId) {
            // redirect from route to next route
            if (userType === USER_TYPE_CUSTOMER) {
                window.location.href = `/customer/${userId}/home`;
            }
            if (userType === USER_TYPE_MOSQUE) {
                window.location.href = `/mosque/${userId}/dashboard`;
            }
        }
    }, [isAuthenticated, userId]);

    const setSegment = (selectedSegment) => {
        dispatch({
            type: IS_AGENT_EXIST,
            is_agent_found: false
        });
        dispatch({
            type: IS_CUSTOMER_EXIST,
            is_customer_found: false
        });
        dispatch(setOtpSent(false));
        dispatch({
            type: SET_OTP_SENT,
            status: false
        });
        setSelectedSegment(selectedSegment);
    };

    return (
        <div className="login-tab-content">
            <div>
                <ul className="d-flex login-modal">
                    <li
                        className="screen-details col-12"
                        style={{
                            fontWeight: selectedSegment === "Screen details" ? "600" : "600",
                            backgroundColor:
                                selectedSegment === "Screen details" ? "#D0004B" : "#fff",
                            color: selectedSegment === "Screen details" ? "#fff" : "#D0004B",
                            borderRadius:
                                selectedSegment === "Screen details" ? "12px 12px 0px 0px" : "",
                        }}
                        onClick={() => setSegment("Screen details")}
                    >
                        {websiteData?.LOGIN_PAGE_LOGIN_SEGMENT_HEADER_BTN_TEXT}{" "}
                    </li>
                </ul>
            </div>
            <div className="row login-board-box">
                {selectedSegment === "Screen details" ? (
                    <UserLogin setSegment={setSegment}/>
                ) : null}
            </div>
        </div>
    );
}

export default LoginTab;
