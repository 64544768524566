import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import Icon from "../../../../../components/Icon/Icon";
import Box from "../../../../../components/Box";
import Button from "../../../../../components/Buttons";
import styled from "styled-components";
import { fetchPlanList } from "../../../../Mosque/mosqueAction";
import truncate from "lodash/truncate";
import { updateMemberSubscription, updateCustomer } from "../../../customerAction";
import useActiveData from "../../../../../customHooks/useActiveData";

const PlansContainer = styled(Box)`
  @media (max-width: 762px) {
    margin-top: 35px;
  }
`;

const PlanBox = styled(Box)`
//   padding: 0 15%;
  margin: 0;
//   margin-left: 172px;
  @media (max-width: 1024px) {
    padding: 0 5%;
  }
  @media (max-width: 762px) {
    padding: 10px;
  }
`;

const PlanDetails = () => {
    const websiteData = useActiveData();
    const dispatch = useDispatch();

    const [selectedPlan, setSelectedPlan] = useState({});
    const plans = useSelector((state) => state.mosqueReducer.memberPlans?.planList);

    const memberSubscription = useSelector((state) => state.customerReducer.activeMemberSubscription);

    useEffect(() => {
        if (memberSubscription && memberSubscription.entity_id) {
            dispatch(fetchPlanList(memberSubscription?.entity_id))
        }
    }, [dispatch, memberSubscription])

    const handleChange = (item) => {
        if (item._id === memberSubscription.subscription_plan_id) {
            setSelectedPlan({}); // reset the plan as he has chosen his current plan
        } else {
            setSelectedPlan(item);
        }
    };

    const renderRecurringType = (type) => {
        if (type === "quarterly") {
            return "quarter";
        }
        if (type === "monthly") {
            return "month";
        }
        return "year";
    };

    const checkIfPlanIsSelected = (item) => {
        if (!_.isEmpty(selectedPlan)) {
            return selectedPlan?._id === item?._id
        }
        return memberSubscription?.subscription_plan_id === item?._id
    }

    const handlePlanChange = () => {
        if (selectedPlan) {
            const customerDetails = {};
            customerDetails.subscription_plan_id = selectedPlan?._id;
            dispatch(updateCustomer(memberSubscription?.customer_id, customerDetails, true));
            const filteredData = plans.filter(item => item?._id === selectedPlan?._id)?.[0];
            customerDetails.membership = filteredData?.plan_name;
            dispatch(updateMemberSubscription(memberSubscription?._id, customerDetails, true));
        }
    }

    return (
        <PlansContainer mb="100px" mt="88px">
           <div className="row" style={{marginTop : "100px", marginBottom : "30px"}}>
              <div className="col-md-12">
                  <div className="text-center">
                      <h2 className="plan-header">{websiteData?.PROFILE_PLAN_PAGE_PROFILE_SEGMENT_HEADER_TEXT}</h2>
                  </div>
              </div>
           </div>
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    <PlanBox className="row">
                        {plans &&
                            plans.map((item, index) => (
                                <div className="plan-changes col-lg-4" key={index}>
                                    <div className="PlanCards change-card">
                                        {/* customCheckboxGreen */}
                                        <div
                                            className={
                                                checkIfPlanIsSelected(item)
                                                    ? "customCheckbox customCheckboxGreen"
                                                    : "customCheckbox customCheckboxGrey"
                                            }
                                        >
                                            <Icon iconSize="xs" iconName="WhiteTick"/>
                                        </div>
                                        <div className="planNo">
                                            <span>{truncate(item?.plan_name, { length: 22 })}</span>
                                        </div>
                                        <div className="Dashborder"></div>
                                        <div className="d-flex align-items-center price price-amount">
                                            €{item.amount}
                                        </div>

                                        {/* planBtnDisable */}
                                        <button
                                            disabled={checkIfPlanIsSelected(item)}
                                            className={checkIfPlanIsSelected(item) ? "planBtn planBtnDisable" : "planBtn planBtnEnable"}
                                            onClick={() => checkIfPlanIsSelected(item) ? null : handleChange(item)}
                                        >
                                            {checkIfPlanIsSelected(item) ? 'Selected Plan' : "Select Plan"}
                                        </button>
                                        <Box className="planDetail plan-detail" my="8px">Plan Details</Box>
                                        <ul>
                                            <li className="plan-li">Billing Frequency - {item?.recurring_type}</li>
                                            <li className="plan-li">Payment Date - 5th of every {renderRecurringType(item?.recurring_type)}</li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                    </PlanBox>
                </div>
            </div>
            <div className="row"  style={{marginTop : "40px", marginBottom : "140px"}}>
                <Button
                    type={!_.isEmpty(selectedPlan) ? 'primary' : 'disabled'}
                    text={websiteData?.PROFILE_PLAN_PAGE_PROFILE_SEGMENT_SAVE_TEXT}
                    width="18% !important"
                    mt="4"
                    mx="auto"
                    height="60px"
                    minWidth="max-content"
                    onClick={() => handlePlanChange()}
                />
            </div>
        </PlansContainer>
    );
};

export default PlanDetails;
