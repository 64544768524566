import React from "react";
import Box from "../../../../../components/Box";
import Button from "../../../../../components/Buttons";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import styled from "styled-components";
import moment from 'moment'
import { borderRadius } from "styled-system";
import _ from 'lodash';
import MosqueNews from "../../CustomerHome/components/MosqueNews";
const Container = styled(Box)`

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 5px;
    p{
        font-size:13px;
        margin-bottom:0px;
      }
  }
 p{
    margin-bottom:0px;
 }
`;

const BlogBody = styled(Box)`

  @media only screen and (max-width: 767px) {
    margin: 0;
    padding: 5px 30px;
    box-shadow: unset;
    margin-bottom: 30px;
    background: #faf4fd;
  }
`;


function DetailsPage({blog, customer_id}) {
    const dataList = [
        {label: "Mosque News", link: `/customer/${customer_id}/home`, isBold: true, color: "#1A1A1A"},
        {label: `Detailed Mosque News`, link:"",color: "#7E7E7E"},
    ];
    return (
        <Container className="blog-details" mx="18%" pt="120px">
            <div className="d-flex justify-content-between align-items-center">
                <Box borderRadius="4px" color="#FFFFFF" px="30px" py="10px" >
                    <p><Breadcrumbs dataList={dataList}/></p>
                </Box>
            </div>
            <BlogBody className="details-card-blog" p="60px 120px" mb="100px">
                <>
                           {/* <Button
                           type="primary"
                           bg="rgba(208, 0, 75, 0.08)"
                           mt="18px"
                           mb="18px"
                           textColor="#D0004B"
                           borderRadius="6px"
                          >
                           <span style={{color:"#D0004B"}}>{blog?.tags}</span>
                           </Button> */}
                            <Box display="flex" flexWrap="wrap">
                        {_.isArray(blog.tags) && blog?.tags?.map(tag => (
                          <Button
                              key={tag}
                              mt="18px"
                              mb="18px"
                              mr="4px"
                              bg="rgba(208, 0, 75, 0.08)"
                              textColor="#D0004B"
                              borderRadius="6px"
                          >
                            <span style={{ color: "#D0004B" }}>{tag}</span>
                          </Button>
                           ))}
                          </Box>
                    <h2>
                        {blog?.heading}
                    </h2>

                    <div className="blog-profile mb-4 mt-4">
                        <img src="https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg"/>
                        <span>{blog?.author_name}</span>
                        <div className="border-right"></div>
                        <span>{moment(new Date(blog?.published_date)).format('DD MMM YYYY')}</span>
                    </div>
                    <img
                        className="mosque-blog-img"
                        src={blog?.preview_image}
                        style={{width: "100%",borderRadius: "12px"}}
                    />
                    <br/>
                    <br/>
                    <p dangerouslySetInnerHTML={{__html: blog?.text}}></p>
                </>
            </BlogBody>
        </Container>
    );
}

export default DetailsPage;
