import React from 'react'
import '../../../../../css/home.css'
import useActiveData from "../../../../../customHooks/useActiveData";


function AboutUs() {
  const websiteData = useActiveData();

  return (
    <div id="about-state">
    <div className='about-us'>
      <div className='col-lg-8 mx-auto'>
      <h2 className='heading-h1'>{websiteData.HOME_PAGE_THIRD_SEGMENT_HEADER_TEXT}</h2>
      <p>
        {websiteData.HOME_PAGE_THIRD_SEGMENT_TEXT}
      </p>
      <button className='common-button'>{websiteData.HOME_PAGE_THIRD_SEGMENT_BTN_TEXT}</button>
      </div>
      {/* <div className='about-grid'>
        {AboutData && AboutData.map((item) =>(
          <div className='about-box'>
            <div className='img-bg'>
            <img src={item.icon} />
            </div>
        </div>
        )) }

      </div> */}
      </div>
    </div>
  )
}

export default AboutUs
