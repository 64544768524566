import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainer";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/Buttons";
import { callApiV2 } from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/Utility";

const DonationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer_id, campaign_id } = useParams();

  const payment_id = "64b7af2201b7db68c82e9942";

  const [isSuccess, setIsSuccess] = useState(false);

  const [campaign, setCampaign] = useState(null);
  const [member, setMember] = useState(null);
  const [customer, setCustomer] = useState(null);

  console.log("customer", customer);
  console.log("campaign", campaign);

  useEffect(() => {
    if (customer_id) {
      callApiCustomer();
    }
  }, [customer_id]);

  const callApiCustomer = async () => {
    const res = await callApiV2({
      method: "get",
      endpoint: `customer/${payment_id}/payment-info?campaign_id=${campaign_id}`,
    });
    if (res?.status === "Success") {
      if (res?.data?.campaign) {
        setCampaign(res?.data?.campaign);
      }

      const resCustomer = await callApiV2({
        method: "get",
        endpoint: `customer/${customer_id}`,
      });
      if (resCustomer?.status === "Success") {
        if (resCustomer?.data?.customer) {
          setCustomer(resCustomer?.data?.customer);
        }
      }
    } else {
      showNotification("error", "Something went wrong...");
    }
  };

  return (
    <div>
      <Banner customerId={customer_id} />
      {!isSuccess ? (
        <BodyContainer
          customer={customer}
          paymentId={payment_id}
          setIsSuccess={setIsSuccess}
        />
      ) : (
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              Donation Successful
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              Lorem Ipsum is simply dummy text of the <br />
              printing and typesetting industry.
            </Label>
          </Box>
          <Box display="flex" justifyContent="center" mt="30px">
            <Button
              onClick={() => navigate(`/customer/${customer_id}/home`)}
              width="40px"
              minWidth="150px"
              type={"primary"}
            >
              Return to Home
            </Button>
          </Box>
        </ResponseContainer>
      )}
    </div>
  );
};

export default DonationPage;
