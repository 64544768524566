import React from 'react'
import { Link } from "react-router-dom";

const ErrorMsg = ({ messageText }) => {
  return (
    <div className="error-box mt-4"><p>{messageText}<Link to="/login">Login Now</Link></p></div>
  )
}

export default ErrorMsg;
