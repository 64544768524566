import React from "react";
import { Link } from 'react-router-dom';

const Banner = ({ customerId }) => {
  return (
    <div className="login_banner">
      <div className="container">
      <Link to={`/customer/${customerId}/home`}><div className="logo_im">LOGO</div></Link>
        <div className="login_content">
          <h3>Choose A DONATION AMOUNT</h3>
          <p>
            Antiren sän nir stenorat lyssna in. Sär gengen. <br />
            Kävagisk nepäskade label supraktig gigartad.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
