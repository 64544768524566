import React  from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingMemberDetails } from "../../../customerAction";
import Icon from "../../../../../components/Icon/Icon";
import { truncate } from "lodash";

const SelectMosqueBoxPlan = () => {
  const dispatch = useDispatch();

  const memberDetails = useSelector((state) => state.customerReducer.memberDetails);
  //const planList = useSelector((state) => state.customerReducer.activeMosquePlans);
   const plans = useSelector((state) => state.mosqueReducer.memberPlans);

   const planList = plans.planList;

  const handleChange = (id) => {
    const selectedPlan = planList.filter((item) => item?._id === id)?.[0];
    dispatch(setOnboardingMemberDetails("subscription_plan_id", selectedPlan?._id));
  };


  const renderRecurringType = (type) => {
    if (type === "quarterly") {
      return "quarter";
    }
    if (type === "monthly") {
      return "month";
    }
    return "January";
  };

  return (
    <div className="row">
      {planList &&
        planList.map((item, index) => (
          <div className="col-md-4" key={index}>
            <div className="PlanCards">
              {/* customCheckboxGreen */}
              <div
                className={
                  memberDetails?.subscription_plan_id === item?._id
                    ? "customCheckbox customCheckboxGreen"
                    : "customCheckbox customCheckboxGrey"
                }
              >
                <Icon iconSize="xs" iconName="WhiteTick" />
              </div>
              <div className="planNo" title={item?.plan_name}>
                Plan - <span>{truncate(item?.plan_name, { length: 18 })}</span>
              </div>
              <div className="Dashborder"></div>
              <div className="d-flex align-items-center price" title={item.amount}>
                ${truncate(item.amount, { length: 8 })}
              </div>

              {/* planBtnDisable */}
              <button
                disabled={memberDetails?.subscription_plan_id === item?._id}
                className={memberDetails?.subscription_plan_id === item?._id ? "planBtn planBtnDisable" : "planBtn planBtnEnable"}
                onClick={() => handleChange(item?._id)}
              >
                Select Plan
              </button>
              <div className="planDetail">Plan Details</div>
              <ul>
                <li>Billing Frequency - {item?.recurring_type}</li>
                <li>
                  Payment Date - {item?.payment_day || 5}th of the{" "} {item?.recurring_type === "quarterly" && "first month of"}{" "}
                  {renderRecurringType(item?.recurring_type)}
                </li>
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SelectMosqueBoxPlan;
