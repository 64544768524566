import React, { useEffect } from "react";
import queryString from "../../../../util/queryString";
import styled from "styled-components";
import Box from "../../../../components/Box";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HelpMosqueCard from "./components/HelpMosqueCard";
import MosqueNews from "./components/MosqueNews";
import useActiveData from "../../../../customHooks/useActiveData";

const Container = styled(Box)`
  height: 100vh;
  // overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

function HomePage() {

  const websiteData = useActiveData();

  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
    if (queryObj?.register === "true") {
      const categoryElement = document.getElementById(`onboarding-state`);
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <Box>
      <Header />
      <Container>
        <HelpMosqueCard />
        <MosqueNews />
        <Footer />
      </Container>
    </Box>
  );
}

export default HomePage;
