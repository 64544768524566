import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { space } from "styled-system";
import Icon from "../Icon";
import Radio from "./Radio";
import "./../../css/elixirweb.css"

const StyledSpan = styled.span`
  ${space}
`;

const checkBoxIcons = ["checkboxEmpty", "squareWithTick"];
const checkBoxIconsWhite = ["checkBoxEmptyWhite", "squareWithTickWhite"];
const switchBoxIcons = ["switchOff", "switchOn"];

const Checkbox = ({
  type,
  checked,
  disabled,
  iconName,
  iconSize,
  iconProps,
  onChange,
  isWhite = false,
  ...props
}) => {
  const handleClick = () => {
    if (onChange && !disabled) {
      onChange(!checked);
    }
  };

  if (type === "radio") {
    return (
      <StyledSpan
        {...props}
        onClick={handleClick}
        data-value={checked}
        data-testid={type}
      >
        <Radio checked={checked} size={iconSize} />
      </StyledSpan>
    );
  }

  const getIconName = () => {
    if (iconName) {
      return checked ? iconName[1] : iconName[0];
    }
    switch (type) {
      case "checkbox":
        return checked ? isWhite ? checkBoxIconsWhite[1] : checkBoxIcons[1] : isWhite ? checkBoxIconsWhite[0] : checkBoxIcons[0];

      case "switch":
        return checked ? switchBoxIcons[1] : switchBoxIcons[0];

      default:
        return "";
    }
  };

  const getIconSize = () => {
    if (iconSize) {
      return iconSize;
    }
    if (type === "switch") {
      return "normal";
    }
    return "xs";
  };

  const renderIcon = () => (
    <Icon
      iconSize={getIconSize()}
      iconName={getIconName()}
      color={checked ? "#D0004B" : "darkGrey.80"}
      //className={
      //  type === "checkbox" &&
      //  (checked ? "et-icon" : "et-icon-deep-grey")
      //}
      {...iconProps}
    />
  );

  if (type === "checkbox" || type === "switch") {
    return (
      <StyledSpan
        {...props}
        onClick={handleClick}
        data-testid={type}
        data-value={checked}
      >
        {renderIcon()}
      </StyledSpan>
    );
  }
  return null;
};

Checkbox.propTypes = {
  onChange: propTypes.func,
  type: propTypes.oneOf(["checkbox", "switch", "radio"]),
  checked: propTypes.bool.isRequired,
  disabled: propTypes.bool,
  iconName: propTypes.arrayOf(propTypes.string),
};

Checkbox.defaultProps = {
  onChange: null,
  disabled: false,
  type: "checkbox",
  iconName: null,
};
export default Checkbox;
