import React from "react";
import { Link } from 'react-router-dom';

const Banner = ({ customerId , payment, isSuccess}) => {

  const getPaymentText = (payment, isSuccess) => {
    if (payment && payment.payment_status !== "paid" && !isSuccess) {
      if (payment.payment_mode === "manual") {
        return `Welcome To Mosqapp. The following payment is a credit payment or iDeal payment. You will be asked to put your payment info on pressing "Proceed To Pay"`;
      } else if (payment.payment_mode === "auto") {
        return `Welcome To Mosqapp. The following payment is a zero charge token payment. You will be asked to put your payment info on pressing "Proceed To Pay". Post payment, you will be charged automatically the payment amount`;
      }
    }
    return '';
  }

  return (
    <div className="login_banner">
      <div className="container">
        <Link to={`/customer/${customerId}/home`}><div className="logo_im">LOGO</div></Link>
        <div className="login_content">
          <h3>Pay Now</h3>
          <p>{getPaymentText(payment, isSuccess)}</p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
