import React from "react";
import { DOTS, usePagination } from "../../customHooks/usePagination";
import styled from "styled-components";
import Icon from "../Icon/Icon";

const PaginationContainer = styled.ul`
  display: flex;
  justify-content: center;
`;

const PaginationItem = styled.li`
  border: 1px solid #e2e2e2;
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 8px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  background-color: ${(props) => (props.active ? "#D0004B" : "#F5F5F5")};
  color: ${(props) => (props.active ? "#ffffff" : "#000000DD")};
  pointer-events: ${(props) => (props.disabled || props.isDot) && "none"};

  &:hover {
    background-color: #d0004b;
    color: #ffffff;
    cursor: pointer;
  }
`;

const ArrowBox = styled(Icon)`
  color: #69788C;
  border: 1px solid #E2E2E2;
  width: 32px;
  height: 32px;
  justify-content: center;
  padding: 0px 5px;
  border-radius: 8px;
  margin: auto 4px;
  background-color: #F5F5F5;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled || props.isDot) && "none"};

  &:hover {
    background-color: #D0004B;
    cursor: pointer; 
    
    & > svg {
      fill: #ffffff !important;
      color: #ffffff !important;
    },
  }
`;

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer>
      <ArrowBox
        iconName="Next"
        transform="rotate(180deg)"
        disabled={currentPage === 1}
        onClick={onPrevious}
      />
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <PaginationItem isDot>&#8230;</PaginationItem>;
        }

        return (
          <PaginationItem
            active={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
      <ArrowBox
        iconName="Next"
        disabled={currentPage === lastPage}
        onClick={onNext}
      />
    </PaginationContainer>
  );
};

export default Pagination;
