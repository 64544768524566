import { SET_AUTH_STATE, SET_IS_AUTHENTICATION, SET_USER_TYPE, SET_USER_ID } from "./appActionType";

export const setAuthState = (authState) => ({
  type: SET_AUTH_STATE,
  authState,
});

export const setAuthentication = (state) => ({
  type: SET_IS_AUTHENTICATION,
  state,
});

export const setUserId = (id) => ({
  type: SET_USER_ID,
  id,
});

export const setUserType = (type) => ({
  type: SET_USER_TYPE,
  userType: type,
});
