import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { layout, space, typography } from "styled-system";

import Icon from "../Icon/Icon";

const Container = styled.div`
  display: flex;
  align-items: center;

  ${space}
  ${layout}
  & > p {
    color: #f47070;
    font-size: 12px;

    font-weight: normal;
    margin-left: 0.5em;
    margin-bottom: 0;
    ${typography}
  }
`;

const ErrorMessage = ({ children, ...rest }) => {
  if (children && children.length > 0) {
    return (
      <Container {...rest}>
        <Icon
          iconName="info"
          color="#F47070"
          height={parseFloat(rest.fontSize) * 1.2}
          width={parseFloat(rest.fontSize) * 1.2}
        />
        <p>{children}</p>
      </Container>
    );
  }

  return null;
};

ErrorMessage.prototypes = {
  children: propTypes.string.isRequired,
};

export default ErrorMessage;
