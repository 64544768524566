import en from '../constants/lang/en.json';

export const getWebsiteContentData = (lang) => {
    if (lang === "en") {
        return en
    }
    return en;
}

export const DataDetails = {
    banner: {
        en: {
            heading: 'Lorem Ipsum English',
            content: '"English Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Join Us"
        },
        nl: {
            heading: 'Lorem Ipsum Netherlands',
            content: '"Netherlands Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        de: {
            heading: 'Lorem Ipsum Deutsch',
            content: '"Deutsch Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        es: {
            heading: 'Lorem Ipsum Espanol',
            content: '"Espanol Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        fr: {
            heading: 'Lorem Ipsum Francais',
            content: '"Francais Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        }
    }
};

export const SliderData = [
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
]
export const AboutData = [
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Lorem ipsum dolor sit amet",
        "paragraph":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam."
    },
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Lorem ipsum dolor sit amet",
        "paragraph":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam."
    },
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Lorem ipsum dolor sit amet",
        "paragraph":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam."
    },

]
