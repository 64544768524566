import React from 'react'
import LoginBanner from './components/LoginBanner'
import LoginTab from './components/LoginTab'

function LoginPage() {
  return (
    <div>
      <LoginBanner />
      <LoginTab />
    </div>
  )
}

export default LoginPage
