import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import Icon from "../../../../../components/Icon";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Buttons";
import { updateCustomer, updateMemberSubscription } from "../../../customerAction";
import { Select } from "antd";
import useMediaUpload from "../../../../../customHooks/useMediaUpload";
import dayjs from "dayjs";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import useOutsideClick from "../../../../../customHooks/useOutsideClick";
import useActiveData from "../../../../../customHooks/useActiveData";
import Box from "../../../../../components/Box/Box";

const AdminProfileBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
  0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  margin-top: 120px;
  @media (max-width: 762px) {
    margin-top: 50px;
    padding: 20px;
  }

  .changePassword {
    font-weight: 500;
    font-size: 12px;
    color: #d0004b;
    text-align: right;
    margin: 15px 0;
  }
`;
const Image = styled.div`
  height: 142px;
  width: 142px;
  background-color: grey;
  border-radius: 50%;
  position: relative;

  img {
    width: 100%;
    border-radius: 50%;
  }

  .Camera {
    height: 35.5px;
    width: 35.5px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    bottom: -2px;
    right: 16px;
    cursor: pointer;
  }

  .ChangeRemove {
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
    bottom: -100px;
    background: #ffffff;
    width: 145px;
    overflow: hidden;

    div {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #d9d9d9;
      }

      &:first-child {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }

  @media (max-width: 520px) {
    height: 80px;
    width: 80px;
    .Camera {
      height: 19px;
      width: 19px;

      svg {
        height: 19px;
        width: 19px;
      }
    }
  }
`;
const MyProfile = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: #1a1a1a;
  margin-left: 20px;
  @media (max-width: 520px) {
    font-size: 20px;
  }
`;

const paymentMethodOption = getPaymentMethodOption();

export default function DetailsInfo() {
    const websiteData = useActiveData();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState();
    const [isNext, setIsNext] = useState(false);
    const [isImagePopupVisible, setChangeImage] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);

    const [changeProfile, setChangeProfile] = useState({});
    const [memberSubsDetails, setMemberSubsDetails] = useState({});

    const customerDetails = useSelector((state) => state.customerReducer.user);
    const memberSubscription = useSelector((state) => state.customerReducer.activeMemberSubscription);

    const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
    const plan_list = memberPlans?.planList;

    const optionsPlan = () => {
        let listValues = [];
        if (plan_list?.length) {
            const list = plan_list?.forEach((reply, index) => {
                listValues.push({
                    ...reply,
                    label: reply?.plan_name,
                    value: reply?._id,
                });
            });
        }
        return listValues;
    };

    useEffect(() => {
        if (customerDetails) {
            setChangeProfile(customerDetails)
        }
        if (memberSubscription) {
            setMemberSubsDetails(memberSubscription)
        }
    }, [customerDetails, memberSubscription]);

    const handleChangeDetails = (keyName, keyValue) => {
        setErrors({});
        setIsNext(true);
        let data = {...changeProfile}
        data[keyName] = keyValue
        setChangeProfile(data);
    };

    const handleUpdate = (keyName, keyValue) => {
        setErrors({});
        setIsNext(true);
        let data = { ...memberSubsDetails }

        if (keyName === "subscription_plan_id") {
            const selectedPlan = plan_list.filter(item => item._id === keyValue)?.[0];
            data["membership"] = selectedPlan?.plan_name
            data[keyName] = keyValue;
        }

        data[keyName] = keyValue
        setMemberSubsDetails(data);
    };

    const validateDetails = () => {
        let isValid = true;
        let message = {};
        if (!changeProfile.contact_name.trim()) {
            isValid = false;
            message["contact_name"] = "Contact name Cannot Be Empty";
        }
        if (!changeProfile.contact_email.trim()) {
            isValid = false;
            message["contact_email"] = "Contact email Cannot Be Empty";
        }
        if (!changeProfile.address.trim()) {
            isValid = false;
            message["address"] = "Address Cannot Be Empty";
        }
        return {
            isValid,
            message
        }
    }

    const handleSubmit = () => {
        let check = validateDetails();
        if (check.isValid) {
            const trimmedDetails = {};
            // Trim each attribute and assign it to trimmedDetails object
            for (const key in changeProfile) {
                if (typeof changeProfile[key] === "string") {
                    trimmedDetails[key] = changeProfile[key].trim();
                } else {
                    trimmedDetails[key] = changeProfile[key];
                }
            }

            dispatch(updateCustomer(trimmedDetails?._id, trimmedDetails));
            dispatch(updateMemberSubscription(memberSubsDetails?._id, memberSubsDetails));
        } else {
            setErrors(check.message);
        }
    }

    const removeProfilePic = () => {
        setIsDropdown(false);
        const data = {};
        data.profile_pic = "";
        dispatch(updateCustomer(changeProfile?._id, data));
    }

    const mediaType = "IMAGE";

    const {handleUpload, status} = useMediaUpload({
        mediaType,
        onSuccess: (res, fileUploaded) => {
            const data = {};
            data.profile_pic = res?.["media-url"];
            dispatch(updateCustomer(changeProfile?._id, data));
            setIsDropdown(false);
        },
    });

    const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!changeProfile.contact_email.trim() || !isEmail(changeProfile.contact_email.trim())) {
            isValid = false;
            error["contact_email"] = "Please enter valid email address";
        }
        return {
            isValid,
            error
        };
    }

    const handleEmailValidation = () => {
        setErrors({});
        let validate = handleValidation();
        if (!validate.isValid) {
            setErrors(validate.error);
            setIsNext(false);
        }
    }

    const setInitialValue = () => {
        setIsDropdown(!isDropdown);
    };

    const ref = useOutsideClick(() => setInitialValue());

    const handleClickUpload = () => {
        if (changeProfile?.profile_pic) {
            setIsDropdown(!isDropdown)
        } else {
            handleUpload();
        }
    }

    return (
        <AdminProfileBox className="contact-box contact-profile" my="80px">
            <div className="text-center">
                <div className="d-flex align-items-center">
                    <Image>
                        {changeProfile?.profile_pic ?
                            <img src={changeProfile?.profile_pic} alt="" width="100%" height="100%"/>
                            :
                            <Box
                                width="100%"
                                height="100%"
                                borderRadius="50%"
                                bg="#D0004B"
                            >
                                <div className={"text-center"} style={{ paddingTop: "30%", color: "white", fontSize: "40px" }}>{changeProfile && changeProfile.contact_name ? changeProfile?.contact_name[0] : ""}</div>
                            </Box>
                        }
                        <div
                            className="Camera"
                        >
                            <Icon iconName="Camera"
                                  onClick={() => handleClickUpload()}
                            />
                        </div>
                        {isDropdown && (
                            <div className="ChangeRemove" style={{zIndex: 9}}>
                                <div onClick={handleUpload}>
                                    Change Photo
                                </div>
                                <div onClick={() => removeProfilePic()}>Remove Photo</div>
                            </div>
                        )}
                    </Image>
                    <MyProfile>{websiteData?.PROFILE_PAGE_HEADER_TEXT}</MyProfile>
                </div>
                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <label style={{marginBottom: "3px"}}>
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_FULL_NAME_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            value={changeProfile?.contact_name}
                            onChange={(e) =>
                                handleChangeDetails("contact_name", e.target.value)
                            }
                            type="text"
                            placeholder="Enter Full Name"
                            className="input-box"
                            height="66px"
                        />
                        {errors?.contact_name && <ErrorMessage>{errors.contact_name}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label style={{marginBottom: "3px"}}>
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_EMAIL_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            onBlur={() => handleEmailValidation()}
                            value={changeProfile?.contact_email}
                            onChange={(e) => handleChangeDetails("contact_email", e.target.value)}
                            type="text"
                            placeholder="Enter Email Address"
                            className="input-box"
                            height="66px"
                        />
                        {errors?.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label style={{marginBottom: "3px"}}>
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_CONTACT_TEXT}
                        </label>
                        <Input
                            // isDisabled
                            value={changeProfile?.contact_phone}
                            countryCodes={true}
                            countryCodeValue={changeProfile?.country_code}
                            setCountryCode={(e) => handleChangeDetails("country_code", e)}
                            onChange={(e) => handleChangeDetails("contact_phone", e.target.value)}
                            placeholder="785-785-4644"
                            className="input-box"
                            type="number"
                            pattern="[0-9]"
                        />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <Label variant="text5" fontWeight={500} marginBottom="3px">
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_ADDRESS_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </Label>
                        <Input
                            value={changeProfile?.address}
                            onChange={(e) => handleChangeDetails("address", e.target.value)}
                            border="1px solid #EFF0F7"
                            boxShadow="0px 2px 6px #13124212"
                            borderRadius="8px"
                            placeholder="Enter Address"
                            className="input-box"
                            type="text"
                        />
                        {errors?.address && <ErrorMessage>{errors.address}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label style={{marginBottom: "3px"}}>
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_PAYMENT_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Select
                            size="middle"
                            value={memberSubsDetails?.payment_method || "Select method"}
                            defaultValue="Select method"
                            onChange={(value) => handleUpdate("payment_method", value)}
                            style={{ width: "100%" }}
                            options={paymentMethodOption}
                        />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <Label variant="text5" fontWeight={500} marginBottom="3px">
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_PLAN_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </Label>
                        <Select
                            size="middle"
                            value={memberSubsDetails?.subscription_plan_id || "Select Plan"}
                            defaultValue="Select Plan"
                            onChange={(value) => handleUpdate("subscription_plan_id", value)}
                            style={{ width: "100%" }}
                            options={optionsPlan()}
                        />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label style={{marginBottom: "3px"}}>
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_MEMBERSHIP_DATE_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            disabled
                            value={dayjs(memberSubsDetails?.member_since || memberSubscription?.created_at).format('DD MMM YYYY')}
                            onChange={(e) => handleChangeDetails("created_at", e.target.value)}
                            placeholder=""
                            className="input-box"
                            type="text"
                            height="66px"
                        />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <Label variant="text5" fontWeight={500} marginBottom="3px">
                            {websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_MEMBERSHIP_TEXT}<span style={{color: "#FE1717"}}>*</span>
                        </Label>
                        <Input
                            isDisabled
                            value={memberSubsDetails?.membership}
                            onChange={(e) => handleChangeDetails("membership", e.target.value)}
                            border="1px solid #EFF0F7"
                            boxShadow="0px 2px 6px #13124212"
                            borderRadius="8px"
                            placeholder=""
                            className="input-box"
                            type="text"
                        />
                    </div>
                </div>
                <Button
                    text={websiteData?.PROFILE_PAGE_PROFILE_SEGMENT_SUBMIT_TEXT}
                    width="25% !important"
                    mt="4"
                    mx="auto"
                    height="60px"
                    type={!isNext ? "disabled" : "primary"}
                    onClick={() => handleSubmit()}
                />
            </div>
        </AdminProfileBox>
    );
}
