import React, { useState, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQueryDetails, submitQuery } from "../../../customerAction";
import Box from "../../../../../components/Box";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
const ContactDetails = () => {
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(false);
  const [errors, setErrors] = useState({});

  const contact_us = useSelector((state) => state.customerReducer.contact_us);
  const isFetching = useSelector((state) => state.customerReducer.isFetching);

  const dataValidation = () => {
    return !!contact_us?.contact_name && !!contact_us?.contact_email && !!contact_us?.message_query;
  }
  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
  });

      const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!contact_us.contact_email) {
          isValid = false;
          error["contact_email"] = "Please enter valid email address";
        }
        if (!contact_us.contact_name) {
          isValid = false;
          error["contact_name"] = "Please enter name";
        }
        return {
          isValid,
          error
        };
      }
  useEffect(() => {
    if (contact_us) {
      setIsNext(dataValidation())
    }
  }, [contact_us])

  const handleSubmit = () => {
    let check = handleValidation ()
    console.log("check", check);
    if (check.isValid) {
      dispatch(submitQuery())
    } else {
      setErrors(check.error);
  }
    
  }
  const handleChangeUser = (keyName, keyValue) => {
    dispatch(setQueryDetails(keyName, keyValue))
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleEmailValidation = () => {
    setErrors({});
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
    }
  }
  return (
    <Box className="new-contact contact-footer" mb="100px" mt="80px">
      <h2 className="contact-header">CONTACT US</h2>
      <div className="contact-box">
        <div className="container">
          <div className="contact-form">
            <div>
              <h5>
                
              </h5>
              <input
               onBlur={() => handleEmailValidation(true)}
                value={contact_us?.contact_name}
                onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                type="text"
                placeholder="Full Name"
                className="input-box"
              />
               {errors.contact_name && <ErrorMessage>{errors.contact_name}</ErrorMessage>}
              <br />
              <br />
              <input
              onBlur={() => handleEmailValidation(true)}
                value={contact_us?.contact_email}
                onChange={(e) => handleChangeUser("contact_email", e.target.value)}
                type="text"
                placeholder="Email Address"
                className="input-box"
              />
               {errors.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
              <br />
              <br />
              <input
                value={contact_us?.message_query}
                onChange={(e) => handleChangeUser("message_query", e.target.value)}
                type="text"
                placeholder="Your Message"
                className="input-box"
                style={{ height: "97px" }}
              />
              <br />
              <br />
              {
                isNext ?
                  <button className="common-button" onClick={() => handleSubmit()}>{isFetching ? "Sending..." : "Send"}</button>
                  :
                  <button className="common-button-disable" disabled>Send</button>
              }
            </div>
            <div>
              <p className="location_icon">
                <a className="d-flex">
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/location.svg" />
                    </span>
                  Infomation technologies building, Victoria Island, Lagos,
                  Nigeria.
                </a>
              </p>
              <p className="location_icon">
                <a className="d-flex">
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/call.svg" />
                    </span>
                  +234 081-1236-4568
                </a>
              </p>
              <p className="location_icon">
                <a className="d-flex">
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/mail.svg" />
                    </span>
                  hello@info.com.ng
                </a>
              </p>
              <div className="d-flex social-icon">
                <a>
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/Youtube.svg" />
                    </span>
                </a>
                <a>
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/Instagram.svg" />
                    </span>
                </a>
                <a>
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/Facebook.svg" />
                    </span>
                </a>
                <a>
                    <span>
                      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/Twitter.svg" />
                    </span>
                </a>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224568.36078842237!2d76.82493783149893!3d28.42285832479986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d582e38859%3A0x2cf5fe8e5c64b1e!2sGurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1682947100069!5m2!1sen!2sin"
                width={300}
                height={150}
                style={{ borderRadius: "10px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ContactDetails;
