import React from "react";
import styled from "styled-components";
import Box from "../Box";
import Icon from "../Icon";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
`;
const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 452px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
`;

const BoxContain = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseContainer = ({ children }) => {
  return (
    <LoginTabContainer>
      <LoginModal>
        <BoxContain>
          <Icon iconName="successStamp"></Icon>
          <Box mt="24px">{children}</Box>
        </BoxContain>
      </LoginModal>
    </LoginTabContainer>
  );
};

export default ResponseContainer;
