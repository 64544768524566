import React, { useEffect } from "react";
import queryString from "../../../../util/queryString";
import styled from "styled-components";
import Box from "../../../../components/Box";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactDetails from "./components/ContactDetails";

const Container = styled(Box)`
  // height: 100vh;
  // overflow: scroll;
  background: #faf4fd;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

function ContactUsPage() {
  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
    if (queryObj?.register === "true") {
      const categoryElement = document.getElementById(`onboarding-state`);
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <Box>
      <Header />
      <Container>
        <ContactDetails />
        <Footer />
      </Container>
    </Box>
  );
}

export default ContactUsPage;
