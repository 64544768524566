import useActiveLanguage from "./useActiveLanguage";
import React, { useEffect, useState } from "react";
import { DataDetails, getWebsiteContentData } from "../constants/HomeData";

const useActiveData = (segment ) => {
  const [constantData, setConstantData] = useState("");
  const activeLanguage = useActiveLanguage();

  useEffect(() => {
    if (activeLanguage) {
      setConstantData(getWebsiteContentData(activeLanguage));
    }
  }, [activeLanguage])

  return constantData;
};

export default useActiveData;
