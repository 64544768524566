import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";
import { callApiV2 } from "../../../../../util/apiCaller";
import { useParams } from "react-router-dom";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
`;
const LoginModal = styled.div`
  width: max-content;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
  @media only screen and (max-width: 767px) {
    width: auto;
    padding: 34px 15px;
    margin: 11px;
  }
`;

const DetailsBody = styled.div`
  margin-top: 30px;
  display: flex;
  grid-gap: 44px;

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }
`;

const BodyContainer = ({ customer, paymentId, setIsSuccess }) => {
  const [userDetails, setUserDetails] = useState({
    contact_name: "",
    contact_email: "",
    country_code: "+31",
    contact_phone: "",
    donation_amount: "",
    currency_symbol: "",
  });

  const [isNext, setIsNext] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [resMessage, setResMessage] = useState(null);

  useEffect(() => {
    let details = {};
    if (customer) {
      details.contact_name = customer?.contact_name;
      details.contact_email = customer?.contact_email;
      details.country_code = customer?.country_code;
      details.contact_phone = customer?.contact_phone;
      details.donation_amount = "";
      details.currency_symbol = "";
    }
    setUserDetails(details);
  }, [customer]);

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...userDetails };

    update[keyName] = keyValue;
    setUserDetails(update);
  };

  const stepValidation = () => {
    return (
      !!userDetails?.contact_name &&
      !!userDetails?.contact_email &&
      !!userDetails?.contact_phone &&
      !!userDetails?.donation_amount
    );
  };
  const { customer_id, campaign_id } = useParams();

  useEffect(() => {
    if (userDetails) {
      setIsNext(stepValidation());
    }
  }, [userDetails]);

  const handleChange = () => {
    setIsApplied(!isApplied);
  };

  const doPayment = async () => {
    console.log("payment", userDetails);
    let payment_res = await callApiV2({
      method: "post",
      endpoint: `campaigns/${campaign_id}/new-payment`,
      body: {
        payment: {
          customer_id,
          amount: userDetails.donation_amount,
          is_anonymous: isApplied,
        },
      },
    });
    console.log(payment_res);
    if (payment_res?.payment_url) {
      window.location = payment_res?.payment_url;
    }
  };

  const handlePayment = useCallback(async () => {
    const res = await callApiV2({
      method: "get",
      endpoint: `customer/payment/${paymentId}/confirm`,
    });
    setResMessage(res?.message);
    setIsSuccess(true);
  }, [paymentId]);

  return (
    <LoginTabContainer>
      <LoginModal>
        <DetailsBody>
          <Box>
            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>
              Full Name
            </Label>
            <Input
              value={userDetails?.contact_name}
              onChange={(e) =>
                setUserDetails({ ...userDetails, contact_name: e.target.value })
              }
              border="1px solid #EFF0F7"
              boxShadow="0px 2px 6px #13124212"
              borderRadius="8px"
              placeholder="Enter Name"
              className="input-box"
              width="300px"
            />
          </Box>
          <Box>
            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>
              Email Address
            </Label>
            <Input
              value={userDetails?.contact_email}
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  contact_email: e.target.value,
                })
              }
              border="1px solid #EFF0F7"
              boxShadow="0px 2px 6px #13124212"
              borderRadius="8px"
              placeholder="Enter Email Address"
              className="input-box"
              width="300px"
            />
          </Box>
        </DetailsBody>
        <DetailsBody>
          <Box>
            <Label mb="12px" variant="text5" fontWeight={500}>
              Contact Number
            </Label>
            <Input
              value={userDetails?.contact_phone}
              countryCodes={true}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              width="300px"
            />
          </Box>
          <Box>
            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>
              Donation Amount
            </Label>
            <Input
              value={userDetails?.donation_amount}
              currencySymbols={true}
              setCurrencySymbols={(e) => handleChangeUser("currency_symbol", e)}
              onChange={(e) =>
                handleChangeUser("donation_amount", e.target.value)
              }
              placeholder="100"
              className="input-box"
              type="number"
              pattern="[0-9]"
              width="300px"
            />
          </Box>
        </DetailsBody>

        <div className="checkbox-container mt-4" style={{ marginLeft: 0 }}>
          <Box pt="4px">Make my donation anonymous</Box>
          {isApplied && <input type="checkbox" checked={isApplied} />}
          <span className="checkmark" onClick={() => handleChange()} />
        </div>

        <Box display="flex" justifyContent="center" mt="30px">
          <Button
            onClick={() => handlePayment()}
            width="40px"
            minWidth="150px"
            type={isNext ? "primary" : "disabled"}
          >
            Donate Now
          </Button>
        </Box>
      </LoginModal>
    </LoginTabContainer>
  );
};

export default BodyContainer;
